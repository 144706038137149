import { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';
import { Paragraph } from 'components';
import {
  ReAuthenticateWrapper,
  ReAuthenticateButtonsWrapper,
  StalePasswordField,
} from './ReAuthenticate.styles';
import { useReAuthenticate } from 'hooks';
import { ERROR_MESSAGES } from 'variables';
import InputUnconventional from '../InputUnconventional/InputUnconventional';
import {
  Control,
  Controller,
  FieldValues,
  Path,
} from 'react-hook-form7';

interface IOwnProps<T extends FieldValues> {
  control: Control<T>;
  withLabel?: boolean;
}

const ReAuthenticate = <T extends FieldValues>({
  children,
  control,
  withLabel = true,
}: PropsWithChildren<IOwnProps<T>>) => {
  const theme = useTheme();
  const { isPasswordProvider } = useReAuthenticate();

  return (
    <ReAuthenticateWrapper>
      {isPasswordProvider && withLabel && (
        <Paragraph variant="bold" mb mbValue={theme.spacing.xs}>
          Re-enter your password to authorise the transfer
        </Paragraph>
      )}

      <ReAuthenticateButtonsWrapper>
        {isPasswordProvider && (
          <StalePasswordField>
            <Controller<T>
              control={control}
              name={"password" as Path<T>}
              rules={{
                required: ERROR_MESSAGES.requiredField,
              }}
              render={({ field, fieldState }) => (
                <InputUnconventional
                  view="moving"
                  id="password"
                  label="Password"
                  type="password"
                  error={fieldState.error?.message}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </StalePasswordField>
        )}
        {children}
      </ReAuthenticateButtonsWrapper>
    </ReAuthenticateWrapper>
  );
};

export default ReAuthenticate;
