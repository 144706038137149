/**
 * Used to create a link to save blob as a file, or open in a new tab.
 *
 * Auto creates an anchor element, clicking on it to open the file, then finally removes the anchor element from the DOM.
 *
 * @param data blob
 * @param filename name of file to save the output.
 * @param openInNewTab boolean, if true, opens the file in a new tab, otherwise downloads the file.
 */
export const createAndOpenDownloadLinkToInMemoryFileData = ({
  data,
  filename,
  openInNewTab = false,
}: {
  data: Blob;
  filename: string;
  openInNewTab?: boolean;
}) => {
  // Initiate variables
  const id = `id-download-attachment-${filename}`;
  const blob = new Blob([data], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);

  // Initialise DOM node with attributes
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('id', id);

  if (openInNewTab) {
    a.target = '_blank';
  } else {
    a.setAttribute('download', filename);
  }

  // Download
  a.click();

  // Clean up
  const element = document.getElementById(id);
  element?.remove();

  URL.revokeObjectURL(url);
};
