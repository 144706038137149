import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { parseRateWithPrecision } from 'utils';
import Button from '../Button/Button';
import InlineLoader from '../InlineLoader/InlineLoader';
import { Row } from '../Row/Row';
import { Paragraph, TextHint } from '../Typography/Typography';

interface IOwnProps {
  rate?: number | null;
  sellCurrencyCode: string;
  buyCurrencyCode: string;
  isRateLoading?: boolean;
  withTitle?: boolean;
  withConvertButton?: boolean;
  revertIconRow?: boolean;
  size?: 'small' | 'medium';
}

const ExchangeRate: FC<IOwnProps> = ({
  rate,
  sellCurrencyCode,
  buyCurrencyCode,
  isRateLoading,
  withTitle = true,
  withConvertButton = true,
  revertIconRow = false,
  size = 'medium',
}) => {
  const theme = useTheme();
  const [isInverted, setIsInverted] = useState(false);

  const firstCurrency = !isInverted ? sellCurrencyCode : buyCurrencyCode;
  const secondCurrency = !isInverted ? buyCurrencyCode : sellCurrencyCode;

  return (
    <Row flex={1}>
      {withTitle && <Paragraph>Exchange rate</Paragraph>}
      <Row
        gap={size === 'small' ? theme.spacing.xxs : theme.spacing.xs}
        flexDirection={revertIconRow ? 'row-reverse' : 'row'}
        alignItems="center"
      >
        {size === 'medium' && (
          <Paragraph variant="bold">
            {`1 ${firstCurrency} = `}
            {isRateLoading || !rate ? (
              <InlineLoader />
            ) : (
              `${parseRateWithPrecision(
                isInverted ? 1 / rate : rate
              )} ${secondCurrency}`
            )}
          </Paragraph>
        )}

        {size === 'small' && (
          <TextHint variant="bold">
            {`1 ${firstCurrency} = `}
            {isRateLoading || !rate ? (
              <InlineLoader />
            ) : (
              `${parseRateWithPrecision(
                isInverted ? 1 / rate : rate
              )} ${secondCurrency}`
            )}
          </TextHint>
        )}

        {withConvertButton && (
          <Button
            variant="link"
            onClick={() => setIsInverted((prev) => !prev)}
            icon="convert-ico"
            disabled={isRateLoading}
            mlIcon={false}
          />
        )}
      </Row>
    </Row>
  );
};

export default ExchangeRate;
