import { ExposedUseTableProps } from 'components/shared/Table/types';
import { useDebounce } from 'hooks';
import {
  TableFilter,
  TableFilterOption,
} from 'components/shared/TableTransactions/utils';
import { useEffect, useRef, useState } from 'react';
import { ITransaction } from 'types/transactions';

const useTableFilteringExperimental = (
  initialFilters: TableFilter[],
  readyToInit: boolean
) => {
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filters, setFilters] = useState<TableFilter[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const debounceSearchValue = useDebounce(searchValue, 500);
  const tableRef = useRef<ExposedUseTableProps<ITransaction>>(null);

  // TODO: remove that later when we do have detected currencies on the client and can init right away
  useEffect(() => {
    if (readyToInit && initialFilters.length && !filters.length) {
      setFilters(initialFilters);
    }
  }, [filters.length, initialFilters, readyToInit]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.setGlobalFilter(debounceSearchValue);
    }
  }, [debounceSearchValue]);

  const setFilterValue = (
    filterTypeName: string,
    filterValue: TableFilterOption
  ) => {
    if (tableRef.current) {
      const newFilters = filters.map((filter) => {
        if (filter.id === filterTypeName) {
          const isValueSetAlready = filter.value.find(
            (existingFilterValue) => existingFilterValue.id === filterValue.id
          );

          if (filterValue.type === 'radio') {
            const filterOptionsWithRadioType = filter.options.filter(
              (option) => option.type === 'radio'
            );

            if (isValueSetAlready) {
              return {
                ...filter,
                value: filter.value.filter(
                  (existingFilterValue) =>
                    !filterOptionsWithRadioType.some(
                      (option) => option.id === existingFilterValue.id
                    )
                ),
              };
            }

            return {
              ...filter,
              value: [...filter.value, filterValue].filter(
                (existingFilterValue) =>
                  existingFilterValue.type !== 'radio' ||
                  existingFilterValue.id === filterValue.id
              ),
            };
          }

          if (filterValue.type === 'checkbox') {
            const isAllCheckbox = filterValue.id === 'all';
            const nonCheckboxFilterCurrentValues = filter.value.filter(
              (val) => val.type !== 'checkbox'
            );

            if (isValueSetAlready) {
              if (isAllCheckbox) {
                return {
                  ...filter,
                  value: nonCheckboxFilterCurrentValues,
                };
              }

              return {
                ...filter,
                value: filter.value.filter(
                  (existingFilterValue) =>
                    existingFilterValue.id !== filterValue.id &&
                    existingFilterValue.id !== 'all'
                ),
              };
            }

            if (isAllCheckbox) {
              return {
                ...filter,
                value: [
                  ...nonCheckboxFilterCurrentValues,
                  ...filter.options.filter(
                    (filterOption) => filterOption.type === 'checkbox'
                  ),
                ],
              };
            }

            const newFilterValue = [...filter.value, filterValue];
            const allCheckboxOption = filter.options.find(
              (filterOption) =>
                filterOption.id === 'all' && filterOption.type === 'checkbox'
            );
            // everything selected except "all", so we want to select "all" automatically
            if (
              newFilterValue.length === filter.options.length - 1 &&
              !newFilterValue.find((val) => val.id === 'all') &&
              allCheckboxOption
            ) {
              return {
                ...filter,
                value: [...newFilterValue, allCheckboxOption],
              };
            }

            return {
              ...filter,
              value: newFilterValue,
            };
          }

          if (filterValue.type === 'date') {
            if (isValueSetAlready) {
              return {
                ...filter,
                value: filter.value.map((existingFilterValue) => {
                  if (existingFilterValue.id === filterValue.id) {
                    return filterValue;
                  }

                  return existingFilterValue;
                }),
              };
            }

            return {
              ...filter,
              value: [...filter.value, filterValue],
            };
          }

          if (filterValue.type === 'number') {
            if (isValueSetAlready) {
              return {
                ...filter,
                value: filter.value.map((existingFilterValue) => {
                  if (existingFilterValue.id === filterValue.id) {
                    return filterValue;
                  }

                  return existingFilterValue;
                }),
              };
            }

            return {
              ...filter,
              value: [...filter.value, filterValue],
            };
          }
        }

        return filter;
      });

      tableRef.current.setAllFilters(newFilters);
      setFilters(newFilters);

      if (!isFilterApplied) {
        setIsFilterApplied(true);
      }
    }
  };

  const resetFilters = () => {
    if (tableRef.current) {
      tableRef.current.setAllFilters(initialFilters);
      setFilters(initialFilters);
      setSearchValue('');

      if (isFilterApplied) {
        setIsFilterApplied(false);
      }
    }
  };

  return {
    tableRef,
    searchValue,
    setSearchValue,
    filters,
    setFilter: setFilterValue,
    resetFilters,
    isFilterApplied,
  };
};

export default useTableFilteringExperimental;
