import { RECIPIENT_STATUS, IContact } from 'types';
import { ContactReviewStatusWrapper } from './ContactReviewStatus.styles';

const ContactReviewStatus = ({
  contact,
}: {
  contact: Partial<
    Pick<
      IContact,
      | 'enabled'
      | 'reviewalStatus'
      | 'shouldPausePayments'
      | 'status'
      | 'isPayoutAccount'
    >
  >;
}) => {
  if (
    contact.status === RECIPIENT_STATUS.rejected ||
    contact.status === RECIPIENT_STATUS.deleted
  ) {
    return (
      <ContactReviewStatusWrapper color="red">
        Invalid
      </ContactReviewStatusWrapper>
    );
  }

  if (contact.shouldPausePayments) {
    return (
      <ContactReviewStatusWrapper color="purple">
        Paused
      </ContactReviewStatusWrapper>
    );
  }

  if (contact.isPayoutAccount) {
    return (
      <ContactReviewStatusWrapper color="blueLight_2">
        Payout
      </ContactReviewStatusWrapper>
    );
  }

  if (contact.reviewalStatus === 'updated') {
    return (
      <ContactReviewStatusWrapper color="coral">
        Updated
      </ContactReviewStatusWrapper>
    );
  }

  if (contact.reviewalStatus === 'new') {
    return (
      <ContactReviewStatusWrapper color="blue">New</ContactReviewStatusWrapper>
    );
  }

  if (contact.reviewalStatus === 'reviewed') {
    return null;
  }

  return (
    <ContactReviewStatusWrapper color="yellow">
      Draft
    </ContactReviewStatusWrapper>
  );
};

export default ContactReviewStatus;
