import { FC } from 'react';
import { useForm, Controller } from 'react-hook-form7';
import { useStoreState } from 'state';
import auth from 'services/auth';
import { SettingsField } from 'pages/Settings/Settings.styles';
import Button from 'components/shared/Button/Button';
import { TotpMultiFactorGenerator } from 'firebase/auth';
import InternalLink from 'components/shared/InternalLink/InternalLink.styles';
import {
  WhiteFooterContainer,
  WhiteContentContainer,
} from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';
import UserSettings from './components/UserSettings/UserSettings';
import { getTotpSettingsPageLink } from 'utils';
import InputUnconventional from 'components/shared/InputUnconventional/InputUnconventional';

interface OwnProps {
  setShowChangePassword: (value: boolean) => void;
}

const GeneralSettings: FC<OwnProps> = ({ setShowChangePassword }) => {
  const { control } = useForm();
  const { user } = useStoreState((state) => state.UserState);
  // TODO: clarify why do we use photoUrl here
  const { photoURL } = auth.currentUser?.providerData[0] ?? {};

  const isTotpEnrolled = auth.currentUser?.multiFactor.enrolledFactors.find(
    (item) => item.factorId === TotpMultiFactorGenerator.FACTOR_ID
  );

  return (
    <>
      <WhiteContentContainer>
        <SettingsField>
          <Controller
            control={control}
            name="email"
            defaultValue={user?.email}
            render={({ field: { value } }) => (
              <InputUnconventional
                id="email"
                label="Email"
                view="moving"
                type="email"
                value={value}
                locked
                disabled
              />
            )}
          />
        </SettingsField>
        {!photoURL ? (
          <Controller
            control={control}
            name="password"
            defaultValue="its-password-variable"
            render={({ field: { value } }) => (
              <InputUnconventional
                id="password"
                label="Password"
                view="moving"
                type="password"
                value={value}
                shouldRenderPasswordIcon={false}
                locked
                disabled
              />
            )}
          />
        ) : (
          <SettingsField>
            <Controller
              control={control}
              name="textWithGoogle"
              defaultValue="Authenticated using Google credentials"
              render={({ field: { value } }) => (
                <InputUnconventional
                  id="textWithGoogle"
                  view="moving"
                  type="text"
                  value={value}
                  locked
                  disabled
                />
              )}
            />
          </SettingsField>
        )}
        <UserSettings />
      </WhiteContentContainer>

      <WhiteFooterContainer>
        {!photoURL && (
          <Button
            variant="link"
            type="button"
            onClick={() => setShowChangePassword(true)}
          >
            Change password
          </Button>
        )}

        {!isTotpEnrolled && (
          <InternalLink to={getTotpSettingsPageLink()}>
            Enable 2nd factor code via an authenticator app
          </InternalLink>
        )}
      </WhiteFooterContainer>
    </>
  );
};

export default GeneralSettings;
