import { FC, useEffect, useState } from 'react';

import { Paragraph, Col, Title, Loader, StaleInputRadioNew } from 'components';
import { Notify } from 'utils';
import { useTheme } from 'styled-components';
import { useStoreState, useStoreActions } from 'state';
import { COMMITTED_CURRENCY_QUESTIONS, ERROR_MESSAGES } from 'variables';
import { useHistory } from 'react-router';
import Button from 'components/shared/Button/Button';
import { getRiskSettingsPageLink } from 'utils/links';
import { IEntityRiskSettings } from 'types';
import { errorHandler } from 'utils/errors';

const StepTwo: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { entityId } = useStoreState((state) => state.UserState);
  const { isEntityRiskSettingsLoading, entityRiskSettings } = useStoreState(
    (state) => state.EntityState
  );
  const { updateEntityRiskSettings, getEntityRiskSettings } = useStoreActions(
    (state) => state.EntityState
  );
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState('');

  const onContinue = async () => {
    if (!entityId) {
      Notify.error(ERROR_MESSAGES.noEntityId);
      return;
    }

    try {
      setIsLoading(true);

      const { data } = await updateEntityRiskSettings({
        entityId,
        settings: {
          riskSettingCommittedCashFlows: question as IEntityRiskSettings['riskSettingCommittedCashFlows'],
        },
      });

      if (data?.success) {
        getEntityRiskSettings(entityId);
        history.push(getRiskSettingsPageLink({ step: '3' }));
      } else {
        errorHandler(data);
        setIsLoading(false);
      }
    } catch (error: any) {
      errorHandler(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (entityRiskSettings?.riskSettingCommittedCashFlows) {
      setQuestion(entityRiskSettings.riskSettingCommittedCashFlows);
    }
  }, [entityRiskSettings]);

  return isLoading ? (
    <Loader size="large" />
  ) : (
    <>
      <Title mb mbValue={theme.spacing.xxl} variant="h1">
        Committed cashflows
      </Title>

      <Col justifyContent="center">
        <Paragraph mb mbValue={theme.spacing.xl}>
          If you have accounts payables and receivables in foreign currencies,
          leaving currency risks unmanaged exposes your finances to potential
          losses, even if at times it may seem worthwhile to wait for better
          rates.
        </Paragraph>

        <Title variant="h3" mb mbValue={theme.spacing.xxl}>
          How do you want to manage committed currency risks?
        </Title>
        <form>
          <Col rowGap={theme.spacing.m} mb mbValue={theme.spacing.xxxl}>
            {COMMITTED_CURRENCY_QUESTIONS.map(({ id, value, name }) => (
              <StaleInputRadioNew
                key={id}
                id={value}
                label={name}
                checked={question === value}
                onChange={() => setQuestion(value)}
              />
            ))}
          </Col>

          <Button
            disabled={isLoading || !question || isEntityRiskSettingsLoading}
            isLoading={isLoading}
            onClick={onContinue}
          >
            Continue
          </Button>
        </form>
      </Col>
    </>
  );
};

export default StepTwo;
