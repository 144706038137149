import { action, computed, thunk } from 'easy-peasy';
import { Firebase } from 'services';
import { BulkPaymentsStateModel } from './types';

export const BulkPaymentsState: BulkPaymentsStateModel = {
  bulkPayments: [],
  bulkPaymentById: computed(
    [(state) => state.bulkPayments],
    (bulkPayments) => (id) => {
      if (!id) {
        return null;
      }
      return bulkPayments.find((item) => item.id === id) || null;
    }
  ),
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  subscribeToBulkPayments: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToBulkPayments({
      ...payload,
      callback: (bulkPayments) => setState(['bulkPayments', bulkPayments]),
    });

    return subscriber;
  }),
};
