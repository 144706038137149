import { FC, useState } from 'react';

import { Icon, Row, StaleInputConvert, Subtitle } from 'components';
import useCurrencies from 'hooks/useCurrencies';
import { useQuery } from 'hooks';
import useAmounts from 'hooks/useAmounts';
import { useStoreActions, useStoreState } from 'state';
import { FirstStepValues } from 'pages/SimpleTransfer/SimpleTransfer';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import Button from 'components/shared/Button/Button';
import { getFirstValidDay } from 'utils/dates';
import dayjs from 'dayjs';
import InputDateUncontrolled from 'components/shared/InputDateUncontrolled/InputDateUncontrolled';
import { useTheme } from 'styled-components';
import TransferSummaryInfo from 'components/shared/TransferSummaryInfo/TransferSummaryInfo';

interface OwnProps {
  onContinue: (values: FirstStepValues) => void;
}

const Amounts: FC<OwnProps> = ({ onContinue }) => {
  const theme = useTheme();
  const { nonTradingDays, systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { isEntityEnabled } = useStoreState((state) => state.UserState);
  const { sellCurrencies } = useStoreState((state) => state.CurrenciesState);
  const { balanceByCurrencyCode } = useStoreState(
    (state) => state.BalancesState
  );
  const { setShowLimitedAccess } = useStoreActions(
    ({ UtilsState }) => UtilsState
  );

  const URLQuery = useQuery();
  const predefinedCurrency = URLQuery.get('predefinedCurrency');
  const predefinedAmount = URLQuery.get('predefinedAmount');
  const [scheduledPaymentDate, setScheduledPaymentDate] = useState<string>('');

  const { sellCurrency, updateSellCurrency } = useCurrencies({
    predefinedSellCurrency: predefinedCurrency,
    isForTransfer: true,
  });

  const sellCurrencyBalance =
    balanceByCurrencyCode(sellCurrency.code)?.availableAmount ?? 0;

  const minDate = getFirstValidDay(new Date(), 2, nonTradingDays);

  const { sellAmountAsNumber, updateSellAmount } = useAmounts({
    rate: 1,
    predefinedSellAmount: predefinedAmount || sellCurrencyBalance.toString(),
    defaultAmount: systemVariables?.transferDefaultAmount,
  });

  const getErrorMessage = () => {
    if (sellAmountAsNumber === 0) {
      return 'Amount should be greater than 0';
    }
  };

  const onContinueHandler = () => {
    if (isEntityEnabled) {
      onContinue({
        sellAmount: sellAmountAsNumber,
        sellCurrency,
        scheduledPaymentDate,
      });
    } else {
      setShowLimitedAccess(true);
    }
  };

  return (
    <FlowStepWrapper>
      <FlowStepContent>
        <FlowContentLeft>
          <Subtitle variant="bold" style={{ marginBottom: 6 }}>
            Amount
          </Subtitle>

          <StaleInputConvert
            id="sellAmount"
            value={sellAmountAsNumber}
            onChange={updateSellAmount}
            currencies={sellCurrencies}
            selectedValue={sellCurrency}
            onSelect={updateSellCurrency}
            error={getErrorMessage()}
          />

          <Row mtValue={theme.spacing.xl} mbValue={theme.spacing.s} mt mb>
            <Subtitle variant="bold">
              To be paid {!!scheduledPaymentDate ? 'on' : ''}
            </Subtitle>
          </Row>

          <Row>
            <InputDateUncontrolled
              id="scheduledPaymentDate"
              type="text"
              view="moving"
              strategy="fixed"
              label={!!scheduledPaymentDate ? 'Date' : 'As soon as possible'}
              value={scheduledPaymentDate}
              onChange={setScheduledPaymentDate}
              defaultValue=""
              calendarProps={{
                defaultActiveStartDate: minDate,
                minDate,
                maxDate: dayjs().add(12, 'month').subtract(1, 'day').toDate(),
                minDetail: 'month',
              }}
              disabledDates={nonTradingDays}
            />

            {!!scheduledPaymentDate && (
              <Button
                ml
                onClick={() => setScheduledPaymentDate('')}
                variant="link"
              >
                <Icon icon="delete-ico" />
              </Button>
            )}
          </Row>
        </FlowContentLeft>

        <FlowContentRight>
          {sellAmountAsNumber > 0 && (
            <>
              <Subtitle variant="bold">Summary</Subtitle>

              <TransferSummaryInfo
                sellCurrency={sellCurrency}
                sellAmount={sellAmountAsNumber}
                scheduledPaymentDate={scheduledPaymentDate}
              />
            </>
          )}
        </FlowContentRight>
      </FlowStepContent>

      <FlowStepFooter>
        <Button disabled={!!getErrorMessage()} onClick={onContinueHandler}>
          Continue
        </Button>
      </FlowStepFooter>
    </FlowStepWrapper>
  );
};

export default Amounts;
