import { action, debug, thunk } from 'easy-peasy';
import { isEqual } from 'lodash';
import get from 'lodash.get';
import { Firebase } from 'services';
import { updateInvoice } from 'services/firebase/invoices';

import { Notify } from 'utils';
import { errorHandler } from 'utils/errors';
import { InvoicesStateModel } from './types';



export const InvoicesState: InvoicesStateModel = {
  hasInvoices: false,
  hasInvoicesDataChanged: false,
  hasInvoicesToCollectDataChanged: false,
  isLoadingHasInvoicesFlag: true,
  invoicesAggregations: null,
  invoicesCurrencies: [],
  isLoadingInvoicesAggregations: true,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  setInvoicesAggregations: action((state, payload) => {
    const invoicesCurrencies = debug(state.invoicesCurrencies);
    const newInvoicesCurrencies = Object.keys(payload?.perCurrency ?? {});

    if (!isEqual(newInvoicesCurrencies, invoicesCurrencies)) {
      state.invoicesCurrencies = newInvoicesCurrencies;
    }

    state.invoicesAggregations = payload;
  }),
  subscribeToInvoice: thunk((_, payload) => {
    try {
      const unsubscribe = Firebase.Invoices.subscribeToInvoice(payload);

      return unsubscribe;
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  getInvoice: thunk(async (_, payload) => {
    try {
      const invoice = await Firebase.Invoices.getInvoiceById(payload);

      return invoice;
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  bindContractRateToInvoice: thunk(async (_, payload) => {
    try {
      const result = await Firebase.Invoices.bindContractRateToInvoice(payload);

      if (!result.data.success) {
        Notify.error(
          result.data.message ??
            'Could not add this prebook rate to the invoice at the moment'
        );
      }

      return result;
    } catch (error: any) {
      Notify.error(
        'Could not add this prebook rate to the invoice at the moment'
      );
    }
  }),
  unbindContractRateToInvoice: thunk(async (_, payload) => {
    try {
      const invoice = await Firebase.Invoices.unbindContractRateToInvoice(
        payload
      );

      return invoice;
    } catch (error: any) {
      Notify.error(
        'Could not remove this prebook rate from the invoice at the moment'
      );
    }
  }),
  cancelInvoiceContractRate: thunk(async (_, payload) => {
    try {
      const response = await Firebase.Invoices.cancelInvoiceContractRate(
        payload
      );

      return response;
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  createInvoiceTracking: thunk(async (_, payload) => {
    try {
      await Firebase.Invoices.createInvoiceTracking(payload);
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  updateInvoiceTracking: thunk(async (_, payload) => {
    try {
      const response = await Firebase.Invoices.updateInvoiceTracking(payload);

      return response;
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  deleteInvoiceTracking: thunk(async (_, payload) => {
    try {
      await Firebase.Invoices.deleteInvoiceTracking(payload);
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  getInvoiceTracking: thunk(async (_, payload) => {
    try {
      const tracking = await Firebase.Invoices.getInvoiceTracking(payload);

      return tracking;
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  updateInvoice: thunk(async (_, payload) => {
    try {
      await updateInvoice(payload);
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  deleteInvoice: thunk(async (_, payload) => {
    try {
      const res = await Firebase.Invoices.deleteInvoice(payload);

      return res.data;
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  checkForInvoices: thunk(async (actions) => {
    try {
      actions.setState(['isLoadingHasInvoicesFlag', true]);

      const res = await Firebase.Invoices.getInvoicesCount();

      if (get(res, 'data.data.count', 0) > 0) {
        actions.setState(['hasInvoices', true]);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      actions.setState(['isLoadingHasInvoicesFlag', false]);
    }
  }),
};
