import { action, computed, thunk } from 'easy-peasy';

import { Firebase } from 'services';
import {
  getRecipientCreationMetadata,
  checkRecipientBankDetails,
} from 'services/firebase/recipients';
import { RECIPIENT_STATUS } from 'types';
import { Notify } from 'utils';
import { RecipientsStateModel } from './types';

export const RecipientsState: RecipientsStateModel = {
  recipients: [],
  isLoadingRecipients: true,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  subscribeToRecipients: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToRecipients({
      ...payload,
      callback: (recipients) => {
        setState(['isLoadingRecipients', false]);
        setState([
          'recipients',
          recipients.filter(
            (recipient) => recipient.status !== RECIPIENT_STATUS.deleted
          ),
        ]);
      },
    });

    return subscriber;
  }),
  createRecipient: thunk(async (_, payload) => {
    const data = await Firebase.createRecipient(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to create recipient');
    }
  }),
  updateRecipient: thunk(async (_, payload) => {
    const data = await Firebase.updateRecipient(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to update recipient');
    }
  }),
  deleteRecipient: thunk(async (_, payload) => {
    const data = await Firebase.deleteRecipient(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to delete recipient');
    }
  }),
  checkRecipientBankDetails: thunk(async (_, payload) => {
    try {
      const { data } = await checkRecipientBankDetails(payload);

      return data;
    } catch (error: any) {
      Notify.error(error.message);
    }
  }),
  getRecipientCreationMetadata: thunk(async (_, payload) => {
    try {
      const { data } = await getRecipientCreationMetadata(payload);

      return data;
    } catch (error: any) {
      Notify.error(error.message);
    }
  }),
  recipientById: computed(
    [(state) => state.recipients],
    (recipients) => (id) => {
      if (id) {
        return recipients.find((item) => item.id.toString() === id.toString());
      }
    }
  ),
  recipientByName: computed(
    [(state) => state.recipients],
    (recipients) => (recipientName) => {
      if (recipientName) {
        return recipients.find(
          (item) => item.recipientName.toString() === recipientName.toString()
        );
      }
    }
  ),
};
