import { FC, useEffect, useState } from 'react';
import StatementsTable from './components/StatementsTable/StatementsTable';
import { getWalletCurrencies } from 'services/transactions';
import { errorHandler } from 'utils/errors';
import { Loader, Paragraph } from 'components';
import useUrlValues from 'hooks/useUrlValues';
import { useStoreState } from 'state';

const Statements: FC = () => {
  const { currency, setUrlValue } = useUrlValues('currency');
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const [isLoadingWalletCurrencies, setIsLoadingWalletCurrencies] = useState(
    true
  );
  const [walletCurrencies, setWalletCurrencies] = useState<string[]>([]);

  useEffect(() => {
    getWalletCurrencies()
      .then((res) => setWalletCurrencies(res.data.data ?? []))
      .catch(errorHandler)
      .finally(() => setIsLoadingWalletCurrencies(false));
  }, []);

  useEffect(() => {
    if (walletCurrencies.length && !currency) {
      const isEntityCurrencyInWalletCurrencies = walletCurrencies.includes(
        entityCurrencyCode
      );

      setUrlValue({
        currency: isEntityCurrencyInWalletCurrencies
          ? entityCurrencyCode
          : walletCurrencies[0],
      });
    }
  }, [walletCurrencies, setUrlValue, currency, entityCurrencyCode]);

  if (isLoadingWalletCurrencies) {
    return <Loader size="large" />;
  }

  if (!walletCurrencies.length) {
    return <Paragraph>No transactions found</Paragraph>;
  }

  return (
    <>
      <StatementsTable walletCurrencies={walletCurrencies} />
    </>
  );
};

export default Statements;
