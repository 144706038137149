import { action, thunk } from 'easy-peasy';
import {
  getEntityCashflowsSettings,
  getEntityPayoutSettings,
  getEntityRiskSettings,
  updateEntityCashflowsSettings,
  updateEntityPayoutSettings,
  updateEntityRiskSettings,
} from 'services/firebase';
import { errorHandler } from 'utils/errors';
import { EntityStateModel } from './types';

export const EntityState: EntityStateModel = {
  /* Entity Cashflows Settings */
  entityCashflowsSettings: null,
  isEntityCashflowsSettingsLoading: true,
  setState: action((state: any, payload: [keyof EntityStateModel, any]) => {
    const [prop, to] = payload;
    state[prop] = to;
  }),
  getEntityCashflowsSettings: thunk(async ({ setState }, entityId) => {
    try {
      const { data } = await getEntityCashflowsSettings(entityId);

      if (data.data) {
        setState(['entityCashflowsSettings', data.data]);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setState(['isEntityCashflowsSettingsLoading', false]);
    }
  }),
  updateEntityCashflowsSettings: thunk(
    async ({ setState }, { entityId, settings }) => {
      const response = await updateEntityCashflowsSettings(entityId, settings);
      setState(['entityCashflowsSettings', settings]);
      return response;
    }
  ),
  /* Entity Payout Settings */
  entityPayoutSettings: null,
  isEntityPayoutSettingsLoading: true,
  getEntityPayoutSettings: thunk(async ({ setState }, entityId) => {
    try {
      const { data } = await getEntityPayoutSettings(entityId);

      if (data.data) {
        setState(['entityPayoutSettings', data.data]);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setState(['isEntityPayoutSettingsLoading', false]);
    }
  }),
  updateEntityPayoutSettings: thunk(
    async ({ setState }, { entityId, settings }) => {
      const response = await updateEntityPayoutSettings(entityId, settings);
      setState(['entityPayoutSettings', settings]);
      return response;
    }
  ),
  /* Entity Risk Settings */
  entityRiskSettings: null,
  isEntityRiskSettingsLoading: true,
  getEntityRiskSettings: thunk(async ({ setState }, entityId) => {
    try {
      const { data } = await getEntityRiskSettings(entityId);

      if (data.data) {
        setState(['entityRiskSettings', data.data]);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setState(['isEntityRiskSettingsLoading', false]);
    }
  }),
  updateEntityRiskSettings: thunk(
    async ({ setState }, { entityId, settings }) => {
      const response = await updateEntityRiskSettings(entityId, settings);
      setState(['entityRiskSettings', settings]);
      return response;
    }
  ),
};
