import { Column } from 'react-table';
import { InlineLoader, Paragraph, Row } from 'components';
import { errorHandler } from 'utils/errors';
import { MonthForStatementDownload } from './StatementsDownloadTable';
import { useState } from 'react';
import { createAndOpenDownloadLinkToInMemoryFileData } from 'utils/createDownloadLinkForPdfAsBlob';
import { getWalletTransactionsPdfByDate } from 'services/transactions';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';

export const generateStatementsTableColumns = (
  currency: string
): Column<MonthForStatementDownload>[] => [
  {
    Header: 'Month',
    accessor: 'label',
    Cell: ({ value }) => <Paragraph>{value}</Paragraph>,
    width: 120,
  },
  {
    accessor: 'from',
    Cell: ({ row }) => {
      const [isLoadingPdf, setIsLoadingPdf] = useState(false);
      const getStatementsAsPdf = async () => {
        try {
          if (currency) {
            setIsLoadingPdf(true);

            const result = await getWalletTransactionsPdfByDate({
              from: row.original.from,
              to: row.original.to,
              currency,
            });

            createAndOpenDownloadLinkToInMemoryFileData({
              data: result.data,
              filename: `statements_${row.original.label}.pdf`,
              openInNewTab: false,
            });
            setIsLoadingPdf(false);
          }
        } catch (error) {
          errorHandler(error);
          setIsLoadingPdf(false);
        }
      };

      return (
        <Row flex={1} justifyContent="end">
          <ActionButton
            disabled={isLoadingPdf}
            onClick={getStatementsAsPdf}
            maxWidth="fit-content"
          >
            Download
            {isLoadingPdf && <InlineLoader />}
          </ActionButton>
        </Row>
      );
    },
    width: 120,
  },
];
