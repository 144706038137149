import {
  IEntityDetails,
  IResponse,
  IEntityLoadingState,
  IEntityPayoutAccount,
  IFundingAccount,
} from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import {
  IClient,
  IEntityCashflowsSettings,
  IEntityInvite,
  IEntityUser,
  IEntity,
  TEntityPackages,
  IEntityApprovalSettings,
  IEntityPayoutSettings,
  IEntityRiskSettings,
} from 'types/entities';
import firebase from 'firebase/compat/app';

import { TEntityPackageKeys, IPermitRoleV2 } from 'types/permissions';
import firestore from 'services/firestore';
import { errorHandler } from 'utils/errors';
import { openDoc, openQuery } from 'utils';

export interface CreateEntityPayload {
  companyName: string;
}

export const createEntity = async (payload: CreateEntityPayload) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<
      IResponse<IEntity>
    >('entities', payload);

    return data;
  } catch (error: any) {
    errorHandler(error);
    return;
  }
};

export const getEntityUsers = async ({ entityId }: { entityId: string }) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IEntityUser[]>>(
    `entities/${entityId}/users`
  );

export const getEntityInvites = async ({ entityId }: { entityId: string }) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IEntityInvite[]>>(
    `entities/${entityId}/invites`
  );

export const getEntityRoles = async (entityId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IPermitRoleV2[]>>(
    `entities/${entityId}/roles`
  );

export const editEntityUserRoles = async ({
  entityId,
  userId,
  roles,
}: {
  entityId: string;
  userId: string;
  roles: string[];
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse>( // TODO fix type
    `entities/${entityId}/users/${userId}/roles`,
    { roles }
  );

export const editEntityUserAttributes = async ({
  entityId,
  userId,
  attributes,
}: {
  entityId: string;
  userId: string;
  attributes: string[];
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `entities/${entityId}/users/${userId}/attributes`,
    { attributes }
  );

export const getPackages = async (entityId: string) => {
  const { data } = await AxiosPrivateFirebaseInstance.get<
    IResponse<TEntityPackages>
  >(`entities/${entityId}/packages`);

  return data.data;
};

export const savePackages = async (
  entityId: string,
  packages: TEntityPackages
) => {
  const { data } = await AxiosPrivateFirebaseInstance.put<
    IResponse<TEntityPackages>
  >(`entities/${entityId}/packages`, packages);

  return data.data;
};

export const getUserPermissionsOnEntity = async ({
  userId,
  entityId,
}: {
  userId: string;
  entityId: string;
}) => {
  const { data } = await AxiosPrivateFirebaseInstance.get<IResponse<string[]>>(
    `entities/${entityId}/users/${userId}/permissions`
  );

  return data.data;
};

export const requestFreeTrial = async (
  entityId: string,
  entityPackage: TEntityPackageKeys
) =>
  AxiosPrivateFirebaseInstance.post<IResponse<TEntityPackageKeys>>(
    `entities/${entityId}/packages/free_trial`,
    {
      entityPackage,
    }
  );

export const setEntityApprover = async ({
  userId,
  entityId,
}: {
  userId: string;
  entityId: string;
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `entities/${entityId}/approver/${userId}`
  );

export const switchApprovalFlowForEntity = async ({
  entityId,
}: {
  entityId: string;
}) =>
  AxiosPrivateFirebaseInstance.put<IResponse>(
    `entities/${entityId}/settings/approvals/enabled-switch`
  );

export const setEntityApprovalSettings = async ({
  entityId,
  settings,
}: {
  entityId: string;
  settings: Partial<IEntityApprovalSettings>;
}) =>
  AxiosPrivateFirebaseInstance.put<IResponse>(
    `entities/${entityId}/settings/approvals`,
    {
      settings,
    }
  );

export interface GetEntityCompanyDetailsParams {
  entityId: string;
}

export const getEntityCompanyDetails = async (entityId: string) =>
  AxiosPrivateFirebaseInstance.get<
    IResponse<Pick<IEntityDetails, 'countriesSendingMoneyTo'>>
  >(`entities/${entityId}/companyDetails`);

export const subscribeToEntityLoadingState = ({
  entityId,
  callback,
}: {
  entityId: string;
  callback: (entitiesLoadingState: IEntityLoadingState | null) => void;
}) =>
  firestore
    .collection('entitiesLoadingState')
    .doc(entityId)
    .onSnapshot((doc) => callback(openDoc(doc)));

export const subscribeToEntitiesLoadingState = ({
  entityIds,
  callback,
}: {
  /** @param entityIds - should not be more than 10 ids because of firestore limitation  */
  entityIds: string[];
  callback: (entitiesLoadingState: IEntityLoadingState[]) => void;
}) =>
  firestore
    .collection('entitiesLoadingState')
    .where(firebase.firestore.FieldPath.documentId(), 'in', entityIds)
    .onSnapshot((query) => callback(openQuery(query)), errorHandler);

export const getAccountantEntitySummaries = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<IClient[]>>(
    `entities/accountants/summary`
  );

export const getEntityPayoutAccounts = async (entityId: string) =>
  AxiosPrivateFirebaseInstance.get<
    IResponse<Record<string, IEntityPayoutAccount>>
  >(`entities/${entityId}/payout-accounts`);

export const getFundingAccounts = async (entityId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IFundingAccount[]>>(
    `/entities/${entityId}/funding-accounts`
  );

export const getEntityCashflowsSettings = async (entityId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IEntityCashflowsSettings>>(
    `/entities/${entityId}/cashflows-settings`
  );

export const updateEntityCashflowsSettings = async (
  entityId: string,
  settings: Partial<IEntityCashflowsSettings>
) =>
  AxiosPrivateFirebaseInstance.put<IResponse<void>>(
    `/entities/${entityId}/cashflows-settings`,
    settings
  );

export const getEntityPayoutSettings = async (entityId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IEntityPayoutSettings>>(
    `/entities/${entityId}/payout-settings`
  );

export const updateEntityPayoutSettings = async (
  entityId: string,
  settings: IEntityPayoutSettings
) =>
  AxiosPrivateFirebaseInstance.put<IResponse<void>>(
    `/entities/${entityId}/payout-settings`,
    settings
  );

export const getEntityRiskSettings = async (entityId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IEntityRiskSettings>>(
    `/entities/${entityId}/risk-settings`
  );

export const updateEntityRiskSettings = async (
  entityId: string,
  settings: IEntityRiskSettings
) =>
  AxiosPrivateFirebaseInstance.put<IResponse<void>>(
    `/entities/${entityId}/risk-settings`,
    settings
  );
