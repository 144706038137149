import { action, thunk, computed } from 'easy-peasy';

import { Firebase } from 'services';
import { createRateContract } from 'services/firebase/rateContracts';
import { errorHandler } from 'utils/errors';
import { RateContractsStateModel } from './types';

export const RateContractsState: RateContractsStateModel = {
  rateContracts: [],
  rateContractsByCurrencyPair: computed(
    [(state) => state.rateContracts],
    (rateContracts) => (sellCurrencyCode, buyCurrencyCode) =>
      rateContracts.filter(
        ({ sellCurrency, buyCurrency }) =>
          sellCurrency === sellCurrencyCode && buyCurrency === buyCurrencyCode
      ) || []
  ),
  rateContractsByBuyCurrency: computed(
    [(state) => state.rateContracts],
    (rateContracts) => (currencyCode) =>
      rateContracts.filter(
        (contract) => contract.buyCurrency === currencyCode
      ) || []
  ),
  rateContractById: computed(
    [(state) => state.rateContracts],
    (rateContracts) => (id) =>
      rateContracts.find((item) => item.id === id) || null
  ),
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  subscribeToRateContracts: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToRateContracts({
      ...payload,
      callback: (rateContracts) => setState(['rateContracts', rateContracts]),
    });

    return subscriber;
  }),
  createRateContract: thunk(async (_, payload) => {
    const data = await createRateContract(payload);

    return data;
  }),
  getRateContractCancelValue: thunk(async (_, payload) => {
    try {
      const cancelValue = await Firebase.Invoices.getContractRateCancelValue(
        payload
      );

      return cancelValue;
    } catch (error: any) {
      errorHandler(error);
    }
  }),
};
