import { FC } from 'react';
import useUrlValues from 'hooks/useUrlValues';
import InvoicesTable from '../InvoicesTable/InvoicesTable';
import GroupedInvoicesTable from '../GroupedInvoicesTable/GroupedInvoicesTable';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { IInvoicesTableActions } from '../InvoicesTable/types';
import { IGroupedInvoicesTableActions } from '../GroupedInvoicesTable/types';
import { IPaymentRun } from 'types/paymentRuns';

interface IOwnProps {
  invoicesTableActionsRef?: React.MutableRefObject<
    IInvoicesTableActions | undefined
  >;
  groupedInvoicesTableActionsRef?: React.MutableRefObject<
    IGroupedInvoicesTableActions | undefined
  >;
  paymentRun?: IPaymentRun;
}

const Tables: FC<IOwnProps> = ({
  invoicesTableActionsRef,
  groupedInvoicesTableActionsRef,
  paymentRun,
}) => {
  const { tab, grouped } = useUrlValues('tab', 'grouped');
  const { isMobile } = useDeviceWidth();

  if (grouped || (tab !== 'paymentRun' && isMobile)) {
    return (
      <GroupedInvoicesTable
        invoicesTableActionsRef={groupedInvoicesTableActionsRef}
      />
    );
  }

  return (
    <InvoicesTable
      invoicesTableActionsRef={invoicesTableActionsRef}
      paymentRun={
        tab === 'paymentRun' || tab === 'outstanding' ? paymentRun : undefined
      }
    />
  );
};

export default Tables;
