import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router';
import useAmounts from 'hooks/useAmounts';
import useCurrencies from 'hooks/useCurrencies';
import { useStoreState } from 'state';
import useCurrencyRate from 'hooks/useCurrencyRate';
import {
  Paragraph,
  Row,
  Col,
  Subtitle,
  StaleInputConvert,
  StaleInputRadioNew,
  PermissionsChecker,
  Icon,
  Title,
} from 'components';
import Button from 'components/shared/Button/Button';
import Card from 'components/shared/Card/Card.styles';
import { RADIO_DATA } from './variables';
import { getPrebookPageLink, getTransfersPageLink } from 'utils/links';
import { AwaitingRegistrationDashboardSectionWrapper } from '../../AwaitingRegistrationDashboard.styles';

const TransferPrebookTile: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const {
    sellCurrencies,
    buyCurrencies,
    prebookableSellCurrencies,
    prebookableBuyCurrencies,
  } = useStoreState((state) => state.CurrenciesState);
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const [transferDirection, setTransferDirection] = useState('paymentToMake');

  const {
    buyCurrency,
    sellCurrency,
    buyCurrencyCode,
    sellCurrencyCode,
    updateBuyCurrency,
    updateSellCurrency,
  } = useCurrencies({
    isForTransfer: true,
  });

  const { rate, isLoading: isRateLoading } = useCurrencyRate({
    sellCurrency: sellCurrency?.code,
    buyCurrency: buyCurrency?.code ?? systemVariables?.defaultBuyCurrency,
    useCachedRate: true,
  });

  const {
    sellAmountAsNumber,
    updateSellAmount,
    updateBuyAmount,
    buyAmountAsNumber,
    setFixedSide,
  } = useAmounts({
    rate,
    defaultAmount: systemVariables?.transferDefaultAmount,
  });

  const onContinueTransfer = () => {
    if (transferDirection === 'receiptToCollect') {
      history.push(
        getTransfersPageLink({
          predefinedBuyAmount: buyAmountAsNumber.toString(),
          predefinedSellCurrency: sellCurrencyCode,
        })
      );
      return;
    }

    history.push(
      getTransfersPageLink({
        predefinedBuyAmount: buyAmountAsNumber.toString(),
        predefinedBuyCurrency: buyCurrencyCode,
      })
    );
  };

  const onContinuePrebook = () => {
    if (transferDirection === 'receiptToCollect') {
      history.push(
        getPrebookPageLink({
          predefinedBuyAmount: buyAmountAsNumber.toString(),
          predefinedSellCurrency: sellCurrencyCode,
        })
      );
      return;
    }

    history.push(
      getPrebookPageLink({
        predefinedBuyAmount: buyAmountAsNumber.toString(),
        predefinedBuyCurrency: buyCurrencyCode,
      })
    );
  };

  return (
    <AwaitingRegistrationDashboardSectionWrapper>
      <Card alignSelf="stretch" flex={1}>
        <Row
          flex={1}
          justifyContent="flex-start"
          flexWrap="wrap"
          gap={theme.spacing.s}
        >
          <Icon width="64px" height="64px" icon="transfer" />
          <Title variant="h4">Save on foreign exchange</Title>
        </Row>

        <Paragraph mb mt mtValue={theme.spacing.xl}>
          Save on foreign exchange payments or collections with our transparent
          interbank prices and fair fees.
        </Paragraph>

        <Col>
          <Subtitle mb variant="bold">
            Foreign currency amount
          </Subtitle>

          <Col
            justifyContent="flex-start"
            flexWrap="wrap"
            gap={theme.spacing.xl}
          >
            {transferDirection === 'receiptToCollect' && (
              <StaleInputConvert
                id="exchangeAmount"
                value={sellAmountAsNumber}
                onChange={updateSellAmount}
                currencies={sellCurrencies}
                selectedValue={sellCurrency}
                onSelect={(value) => updateSellCurrency(value)}
                onFocus={() => setFixedSide('sell')}
              />
            )}
            {transferDirection === 'paymentToMake' && (
              <StaleInputConvert
                id="exchangeAmount"
                value={buyAmountAsNumber}
                onChange={updateBuyAmount}
                currencies={buyCurrencies}
                selectedValue={buyCurrency}
                onSelect={(value) => {
                  updateBuyCurrency(value);
                }}
                onFocus={() => setFixedSide('buy')}
              />
            )}

            <Col gap={theme.spacing.xs}>
              <Paragraph>What would you like to save money on:</Paragraph>
              <Row
                flexWrap="wrap"
                gap={theme.spacing.xl}
                justifyContent="flex-start"
              >
                {RADIO_DATA.map(({ id, value, name }) => (
                  <StaleInputRadioNew
                    key={id}
                    id={value}
                    label={name}
                    checked={transferDirection === value}
                    onChange={() => setTransferDirection(value)}
                  />
                ))}
              </Row>
            </Col>
          </Col>
        </Col>

        <Row>
          <PermissionsChecker action="create" resource="transfers">
            <Button
              mt
              mtValue={theme.spacing.xxl}
              disabled={isRateLoading}
              onClick={onContinueTransfer}
            >
              See exchange costs
            </Button>
          </PermissionsChecker>
        </Row>
      </Card>

      <Card alignSelf="stretch" flex={1}>
        <Row
          flex={1}
          justifyContent="flex-start"
          flexWrap="wrap"
          gap={theme.spacing.s}
        >
          <Icon width="64px" height="64px" icon="prebook" />
          <Title variant="h4">Book early. Enjoy certainty</Title>
        </Row>

        <Paragraph mb mt mtValue={theme.spacing.xl}>
          Secure exchange rates today without anything to pay upfront, and use
          them for future invoices.
        </Paragraph>
        <Col>
          <Subtitle mb variant="bold">
            Foreign currency amount
          </Subtitle>

          <Col
            justifyContent="flex-start"
            flexWrap="wrap"
            gap={theme.spacing.xl}
          >
            {transferDirection === 'receiptToCollect' && (
              <StaleInputConvert
                id="exchangeAmount"
                value={sellAmountAsNumber}
                onChange={updateSellAmount}
                currencies={prebookableSellCurrencies}
                selectedValue={sellCurrency}
                onSelect={(value) => updateSellCurrency(value)}
                onFocus={() => setFixedSide('sell')}
              />
            )}
            {transferDirection === 'paymentToMake' && (
              <StaleInputConvert
                id="exchangeAmount"
                value={buyAmountAsNumber}
                onChange={updateBuyAmount}
                currencies={prebookableBuyCurrencies}
                selectedValue={buyCurrency}
                onSelect={(value) => {
                  updateBuyCurrency(value);
                }}
                onFocus={() => setFixedSide('buy')}
              />
            )}

            <Col gap={theme.spacing.xs}>
              <Paragraph>What would you like to save money on:</Paragraph>
              <Row
                flexWrap="wrap"
                gap={theme.spacing.xl}
                justifyContent="flex-start"
              >
                {RADIO_DATA.map(({ id, value, name }) => (
                  <StaleInputRadioNew
                    key={id}
                    id={value}
                    label={name}
                    checked={transferDirection === value}
                    onChange={() => setTransferDirection(value)}
                  />
                ))}
              </Row>
            </Col>
          </Col>
        </Col>

        <Row>
          <PermissionsChecker action="create" resource="prebooks">
            <Button
              mt
              mtValue={theme.spacing.xxl}
              disabled={isRateLoading}
              onClick={onContinuePrebook}
            >
              See prebook costs
            </Button>
          </PermissionsChecker>
        </Row>
      </Card>
    </AwaitingRegistrationDashboardSectionWrapper>
  );
};

export default TransferPrebookTile;
