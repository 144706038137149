import { Button, Paragraph, Popup, Row, Title } from 'components';
import { FC } from 'react';

interface IOwnProps {
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

const ReverseConversionPopup: FC<IOwnProps> = ({
  onClose,
  onConfirm,
  isLoading,
}) => (
  <Popup
    width="400px"
    height="300px"
    HeaderContent={<Title variant="h3">Reverse Conversion</Title>}
    FooterContent={
      <Row flex={1}>
        <Button onClick={onConfirm} isLoading={isLoading} disabled={isLoading}>
          Confirm
        </Button>
        <Button variant="secondary" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
      </Row>
    }
    onClose={onClose}
  >
    <Paragraph>
      You are about to create an journal entry in Xero reversing this FX
      transaction. Are you sure you would like to proceed?
    </Paragraph>
  </Popup>
);

export default ReverseConversionPopup;
