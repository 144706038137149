import { IContact, IEntityPayoutSettings, RECIPIENT_STATUS } from 'types';
import { IPayoutContactData } from './types';

export const populateContactsForDropdown = (
  savedPayoutAccounts: IEntityPayoutSettings['payoutContacts'],
  formContactsNames: string[],
  recipientById: (id: string) => IContact | undefined
) =>
  Object.values(savedPayoutAccounts || {}).reduce<
    Record<
      string,
      {
        payoutContact: IContact;
        currency: string;
      }
    >
  >((accumulator, payoutContact) => {
    const contactFromState = recipientById(payoutContact);

    if (
      !contactFromState ||
      !contactFromState.enabled ||
      contactFromState.status === RECIPIENT_STATUS.draft
    ) {
      return accumulator;
    }

    const accountRowId = `contact_${
      contactFromState.currency + contactFromState.recipientName
    }`;

    formContactsNames.push(accountRowId);

    accumulator[accountRowId] = {
      payoutContact: contactFromState,
      currency: contactFromState.currency,
    };

    return accumulator;
  }, {});

export const generatePayoutSettingsToSave = ({
  formPayoutContacts,
  savedPayoutContacts,
}: {
  formPayoutContacts: { currency?: string; payoutContact?: IContact }[];
  savedPayoutContacts: IEntityPayoutSettings['payoutContacts'];
}) => {
  const dataToReturn: IEntityPayoutSettings['payoutContacts'] = {};

  const currenciesSet = new Set<string>(Object.keys(savedPayoutContacts || {}));

  formPayoutContacts.forEach(({ currency }) => {
    if (currency) {
      currenciesSet.add(currency);
    }
  });

  Array.from(currenciesSet).forEach((currency) => {
    const contact = formPayoutContacts.find(
      (formPayoutContact) => formPayoutContact.currency === currency
    );

    if (!!contact?.payoutContact?.id) {
      dataToReturn[currency] = contact.payoutContact.id;
    }
  });

  return dataToReturn;
};

export const generatePayoutContactData = (
  contact: IContact
): IPayoutContactData => {
  const { recipientName } = contact;

  return {
    id: recipientName,
    value: contact,
    name: recipientName,
  };
};

export const generateLabelText = ({
  isCurrencySelected,
  selectedCurrency,
  value,
  payoutContactsData,
}: {
  isCurrencySelected: boolean;
  selectedCurrency?: string;
  value?: IContact;
  payoutContactsData: IPayoutContactData[];
}) => {
  let message;

  if (isCurrencySelected) {
    if (value) {
      message = undefined;
    } else {
      if (payoutContactsData.length > 0) {
        message = `Please select ${selectedCurrency} contact`;
      } else {
        message = 'No contacts available, please add one';
      }
    }
  } else {
    message = 'Please select CCY';
  }

  return message;
};
