import { ContextMenu, Row } from 'components';
import { SetStateAction, Dispatch, useMemo, useState } from 'react';
import {
  IWalletTransaction,
  IWalletTransactionWritableResourceWithWriteDetails,
} from '../../types';
import ConversionInner from 'components/shared/ConversionInner/ConversionInner';
import TransferInner from 'components/shared/TransferInner/TransferInner';
import { writeWalletTransactionToSourceSystem } from 'services/transactions';
import { errorHandler } from 'utils/errors';
import WriteWarningPopup from './components/WriteWarningPopup/WriteWarningPopup';
import WriteBankTransferPopup from './components/WriteBankTransferPopup/WriteBankTransferPopup';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import PopupMatchInvoices, {
  IResourceToMatchTo,
} from 'components/shared/PopupMatchInvoices/PopupMatchInvoices';
import { walletTransactionToResourceToMatchTo } from './utils';
import { useStoreState } from 'state';
import ReverseConversionPopup from './components/ReverseConversionPopup/ReverseConversionPopup';
import { reverseConversion } from 'services/firebase/conversions';

interface IOwnProps {
  walletTransaction: IWalletTransaction;
  setWalletTransactions: Dispatch<SetStateAction<IWalletTransaction[]>>;
}

const StatementTableActionsCell = ({
  walletTransaction,
  setWalletTransactions,
}: IOwnProps) => {
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { isIntegratedWithXero } = useIntegrationEngine();
  const [showConversionDetails, setShowConversionDetails] = useState(false);
  const [showTransferDetails, setShowTransferDetails] = useState(false);
  const [showWriteWarningPopup, setShowWriteWarningPopup] = useState(false);
  const [isWritingToSourceSystem, setIsWritingToSourceSystem] = useState(false);
  const [showWriteBankTransferPopup, setShowWriteBankTransferPopup] = useState(
    false
  );
  const [showReverseConversionPopup, setShowReverseConversionPopup] = useState(
    false
  );
  const [isReversingConversion, setIsReversingConversion] = useState(false);
  const [
    resourceToMatchTo,
    setResourceToMatchTo,
  ] = useState<IResourceToMatchTo | null>(null);

  const contextMenuData = useMemo(() => {
    const menuItems = [];

    const {
      type,
      writableResources,
      currency: walletTransactionCurrency,
    } = walletTransaction;

    if (type === 'FX Exchange' || type === 'Transfer out') {
      menuItems.push({
        id: 'details',
        title: 'Details',
        onClick: () => {
          if (type === 'FX Exchange') {
            setShowConversionDetails(true);
          }

          if (type === 'Transfer out') {
            setShowTransferDetails(true);
          }
        },
      });
    }

    if (
      (type === 'FX Exchange' ||
        type === 'Transfer out' ||
        type === 'Transfer in') &&
      writableResources.find(
        (resource) =>
          resource.status === 'error' ||
          resource.status === 'permission-error' ||
          resource.status === undefined
      ) &&
      isIntegratedWithXero
    ) {
      menuItems.push({
        id: 'write-to-source-system',
        title: 'Write to Xero',
        onClick: async () => {
          setShowWriteWarningPopup(true);
        },
      });
    }

    const isReconciled =
      writableResources.filter(
        (writableResource) =>
          !writableResource.status ||
          writableResource.status === 'error' ||
          writableResource.status === 'permission-error'
      ).length === 0;
    const hasInvoicePayments = writableResources.find(
      (writableResource) => writableResource.type === 'invoicePayment'
    );

    if (
      (type === 'Transfer in' || type === 'Transfer out') &&
      !isReconciled &&
      !hasInvoicePayments &&
      isIntegratedWithXero
    ) {
      menuItems.push({
        id: 'create-bank-transfer',
        title: 'Write bank transfer to Xero',
        onClick: () => setShowWriteBankTransferPopup(true),
      });

      const isSameCurrencyTransfer = writableResources.every(
        (resource) => resource.currency === walletTransactionCurrency
      );

      if (
        (!isSameCurrencyTransfer &&
          walletTransactionCurrency === entityCurrencyCode) ||
        isSameCurrencyTransfer
      ) {
        menuItems.push({
          id: 'match-invoices',
          title: 'Match payment to invoices in Xero',
          onClick: () => {
            walletTransactionToResourceToMatchTo(walletTransaction)
              .then(setResourceToMatchTo)
              .catch(errorHandler);
          },
        });
      }
    }

    const isReversed = writableResources.find(
      (resource) =>
        (resource as IWalletTransactionWritableResourceWithWriteDetails)
          .action === 'write_undoConversion' && resource.status === 'success'
    );

    if (type === 'FX Exchange' && isReconciled && !isReversed) {
      menuItems.push({
        id: 'reverse-conversion',
        title: 'Reverse in Xero',
        onClick: () => {
          setShowReverseConversionPopup(true);
        },
      });
    }

    return menuItems;
  }, [entityCurrencyCode, isIntegratedWithXero, walletTransaction]);

  const handleConfirmWrite = async () => {
    try {
      if (isWritingToSourceSystem) {
        return;
      }

      setIsWritingToSourceSystem(true);
      setWalletTransactions((prevState) =>
        prevState.map((walletTransactionInner) =>
          walletTransaction.id === walletTransactionInner.id
            ? {
                ...walletTransactionInner,
                writableResources: walletTransactionInner.writableResources.map(
                  (resource) => {
                    if (
                      resource.status === 'error' ||
                      resource.status === 'permission-error' ||
                      !resource.status
                    ) {
                      return { ...resource, status: 'pending' };
                    }

                    return resource;
                  }
                ),
              }
            : walletTransactionInner
        )
      );
      await writeWalletTransactionToSourceSystem(walletTransaction.id);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsWritingToSourceSystem(false);
    }
  };

  const handleConfirmReverseConversion = async () => {
    try {
      setIsReversingConversion(true);
      await reverseConversion(walletTransaction.sourceId);
      setWalletTransactions((prevState) =>
        prevState.map((walletTransactionInner) =>
          walletTransaction.id === walletTransactionInner.id
            ? {
                ...walletTransactionInner,
                writableResources: walletTransactionInner.writableResources.map(
                  (resource) => {
                    if (resource.type === 'conversion') {
                      return { ...resource, status: 'pending' };
                    }

                    return resource;
                  }
                ),
              }
            : walletTransactionInner
        )
      );
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsReversingConversion(false);
      setShowReverseConversionPopup(false);
    }
  };

  if (contextMenuData.length === 0) {
    return null;
  }

  return (
    <>
      <Row flex={1} justifyContent="flex-end">
        <ContextMenu portal list={contextMenuData} />
      </Row>
      {showConversionDetails && (
        <ConversionInner
          conversionId={walletTransaction.sourceId}
          onClose={() => setShowConversionDetails(false)}
        />
      )}
      {showTransferDetails && (
        <TransferInner
          transferId={walletTransaction.sourceId}
          onClose={() => setShowTransferDetails(false)}
        />
      )}
      {showWriteWarningPopup && (
        <WriteWarningPopup
          onClose={() => setShowWriteWarningPopup(false)}
          onConfirm={handleConfirmWrite}
        />
      )}
      {showWriteBankTransferPopup && (
        <WriteBankTransferPopup
          onClose={() => setShowWriteBankTransferPopup(false)}
          record={walletTransaction}
          onSuccess={() => {
            setWalletTransactions((prevState) =>
              prevState.map((walletTransactionInner) =>
                walletTransaction.id === walletTransactionInner.id
                  ? {
                      ...walletTransactionInner,
                      writableResources: walletTransactionInner.writableResources.map(
                        (resource) => {
                          if (
                            resource.status === 'error' ||
                            resource.status === 'permission-error' ||
                            !resource.status
                          ) {
                            return { ...resource, status: 'pending' };
                          }

                          return resource;
                        }
                      ),
                    }
                  : walletTransactionInner
              )
            );
          }}
        />
      )}
      {!!resourceToMatchTo && (
        <PopupMatchInvoices
          onClose={() => setResourceToMatchTo(null)}
          resourceToMatchTo={resourceToMatchTo}
          onSubmitCallback={() => setResourceToMatchTo(null)}
        />
      )}
      {showReverseConversionPopup && (
        <ReverseConversionPopup
          onClose={() => setShowReverseConversionPopup(false)}
          onConfirm={handleConfirmReverseConversion}
          isLoading={isReversingConversion}
        />
      )}
    </>
  );
};

export default StatementTableActionsCell;
