import { action, computed, thunk } from 'easy-peasy';
import { Firebase } from 'services';
import {
  createTransfer,
  getTransferPurposes,
} from 'services/firebase/transfers';

import { Notify } from 'utils';
import { TransfersStateModel } from './types';

export const TransfersState: TransfersStateModel = {
  transfers: [],
  transfersByRateContract: computed(
    [(state) => state.transfers],
    (transfers) => (rateContractId) =>
      transfers.filter((item) => item.contractId === rateContractId)
  ),
  transferById: computed(
    [(state) => state.transfers],
    (transfers) => (transferId) => {
      if (!transferId) {
        return null;
      }

      return transfers.find((item) => item.id === transferId) || null;
    }
  ),
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  subscribeToTransfers: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToTransfers({
      ...payload,
      callback: (rateContracts) => setState(['transfers', rateContracts]),
    });

    return subscriber;
  }),
  createTransfer: thunk(async (_, payload) => {
    const data = await createTransfer(payload);

    return data;
  }),
  getTransferPurposes: thunk(async (_, payload) => {
    try {
      const { data } = await getTransferPurposes(payload);

      if (data.success) {
        return data;
      } else {
        Notify.error(data.message ?? '');
      }
    } catch (error: any) {
      console.warn(error);
    }
  }),
  transfersByBulkId: computed(
    [(state) => state.transfers],
    (transfers) => (bulkId) =>
      transfers.filter((item) => item.bulkPaymentId === bulkId)
  ),
};
