import { action, computed, thunk } from 'easy-peasy';
import { Firebase } from 'services';
import { Notify } from 'utils';
import { CurrenciesStateModel } from './types';

export const CurrenciesState: CurrenciesStateModel = {
  currencies: [],
  followedCurrencies: [],
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  getCurrencies: thunk(async (actions) => {
    const data = await Firebase.getCurrencies();

    if (data) {
      // put USD and EUR at the start
      const sortedCurrencies = data.sort((el) => {
        if (el.code === 'USD' || el.code === 'EUR') {
          return -1;
        } else {
          return 1;
        }
      });

      actions.setState(['currencies', sortedCurrencies]);
    }
  }),
  enabledCurrencies: computed([(state) => state.currencies], (currencies) =>
    currencies
      .filter((currency) => currency.enabled)
      .sort((el) => {
        if (el.code === 'USD' || el.code === 'EUR' || el.code === 'GBP') {
          return -1;
        } else {
          return 1;
        }
      })
  ),
  subscribeToFollowedCurrencies: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToFollowedCurrencies({
      ...payload,
      callback: (followedCurrencies) =>
        setState(['followedCurrencies', followedCurrencies]),
    });

    return subscriber;
  }),
  addFollowedCurrencyPair: thunk(async (_, payload) => {
    const data = await Firebase.addFollowedCurrencyPair(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to create followed currency');
    }
  }),
  deleteFollowedCurrencyPair: thunk(async (_, payload) => {
    const data = await Firebase.deleteFollowedCurrencyPair(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to delete followed currency');
    }
  }),
  updateFollowedCurrencyPair: thunk(async (_, payload) => {
    const data = await Firebase.updateFollowedCurrencyPair(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to update followed currency');
    }
  }),
  currencyByCode: computed(
    [(state) => state.currencies],
    (currencies) => (currencyCode) =>
      currencies.find((item) => item.code === currencyCode) || null
  ),
  followedCurrencyById: computed(
    [(state) => state.followedCurrencies],
    (followedCurrencies) => (currencyId) =>
      followedCurrencies.find((item) => item.id === currencyId) || null
  ),
  sellCurrencies: computed(
    [(state) => state.currencies],
    (currencies) => currencies?.filter((currency) => currency.sell) || []
  ),
  prebookableSellCurrencies: computed(
    [(state) => state.currencies],
    (currencies) =>
      currencies?.filter((currency) => currency.sell && currency.prebookable) ||
      []
  ),
  buyCurrencies: computed(
    [(state) => state.currencies],
    (currencies) => currencies?.filter((currency) => currency.buy) || []
  ),
  prebookableBuyCurrencies: computed(
    [(state) => state.currencies],
    (currencies) =>
      currencies?.filter((currency) => currency.buy && currency.prebookable) ||
      []
  ),
};
