import {
  FC,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import {
  Button,
  Paragraph,
  Popup,
  Row,
  Title,
  Icon,
  Loader,
  Col,
  Subtitle,
  StaleInfo,
  TableSearchInput,
} from 'components';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { getInvoicesFilters } from 'pages/Invoices/utils';
import { IInvoiceFromSearch } from 'types';
import { ExposedUseTableProps } from 'components/shared/Table/types';
import { IInvoicesTableActions } from 'pages/Invoices/components/InvoicesTable/types';
import useInvoices from 'hooks/useInvoices';
import InvoicesTableShared from 'components/shared/InvoicesTable/InvoicesTable';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'variables';
import { callExternalApiWithLoading } from 'utils/fetchers';
import { errorHandler } from 'utils/errors';
import { matchFundingToInvoices } from 'services/funding';
import {
  updateInMemoryInvoicesWithFundingId,
  updateInMemoryInvoicesWithTransferId,
} from './utils';
import Card from 'components/shared/Card/Card.styles';
import { matchTransferToInvoices } from 'services/firebase';
import useDebounce from 'hooks/useDebounce';

export interface IResourceToMatchTo {
  id: string;
  currency: string;
  amount: number;
  senderName: string;
  paymentDate?: string;
  paymentRef?: string;
  type: 'funding' | 'transfer';
}

interface OwnProps {
  onClose: () => void;
  resourceToMatchTo: IResourceToMatchTo;
  onSubmitCallback: () => Promise<void> | void;
}

const PopupMatchInvoices: FC<OwnProps> = ({
  onClose,
  resourceToMatchTo,
  onSubmitCallback,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(resourceToMatchTo.currency);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFirstInvoicesPage, setIsLoadingFirstInvoicesPage] = useState(
    true
  );
  const [selectedInvoices, setSelectedInvoices] = useState<
    IInvoiceFromSearch[]
  >([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const debouncedSearchValue = useDebounce(searchInputValue, 500);

  const tableRef = useRef<ExposedUseTableProps<IInvoiceFromSearch>>(null);
  const invoicesTableActionsRef = useRef<IInvoicesTableActions>();
  const {
    invoices,
    currentPage,
    hasMoreToLoad,
    isLoadingMoreInvoices,
    fetchInvoicesWithSideEffects,
    updateInMemoryInvoices,
  } = useInvoices();

  const selectedInvoicesTotalAmount = selectedInvoices.reduce(
    (acc, invoice) => acc + invoice.amountDue,
    0
  );
  const differenceAmount =
    resourceToMatchTo.amount - selectedInvoicesTotalAmount;

  const fetchFirstInvoicesPage = useCallback(async () => {
    const filters = getInvoicesFilters({
      filterName:
        resourceToMatchTo.type === 'funding' ? 'receivables' : 'payables',
      currency: resourceToMatchTo.currency,
    });

    tableRef.current?.toggleAllRowsSelected(false);

    fetchInvoicesWithSideEffects({
      page: 1,
      searchQuery: debouncedSearchValue ?? '',
      filters,
      reset: true,
    }).finally(() => {
      setIsLoadingFirstInvoicesPage(false);
    });
  }, [fetchInvoicesWithSideEffects, resourceToMatchTo, debouncedSearchValue]);

  useEffect(() => {
    fetchFirstInvoicesPage();
  }, [fetchFirstInvoicesPage]);

  const onLoadMoreItems = useCallback(async () => {
    if (isLoadingMoreInvoices) {
      return;
    }

    return fetchInvoicesWithSideEffects({
      page: currentPage + 1,
      searchQuery: debouncedSearchValue ?? '',
      filters: getInvoicesFilters({
        filterName:
          resourceToMatchTo.type === 'funding' ? 'receivables' : 'payables',
        currency: resourceToMatchTo.currency,
      }),
    });
  }, [
    currentPage,
    fetchInvoicesWithSideEffects,
    resourceToMatchTo,
    isLoadingMoreInvoices,
    debouncedSearchValue,
  ]);

  useImperativeHandle(
    invoicesTableActionsRef,
    () => ({
      updateInMemoryInvoices,
      fetchFirstInvoicesPage,
    }),
    [fetchFirstInvoicesPage, updateInMemoryInvoices]
  );

  const onSubmitMatchHandler = async () => {
    if (differenceAmount !== 0) {
      return;
    }

    if (resourceToMatchTo.type === 'funding') {
      callExternalApiWithLoading({
        externalApiCall: () =>
          matchFundingToInvoices({
            fundingId: resourceToMatchTo.id,
            invoiceIds: selectedInvoices.map((invoice) => invoice.id),
          }),
        loadingHandler: setIsLoading,
        responseHandler: ({ data }) => {
          if (data.success) {
            updateInMemoryInvoices((existingInvoices) =>
              updateInMemoryInvoicesWithFundingId({
                invoices: existingInvoices,
                invoicesForMatch: selectedInvoices,
                fundingId: resourceToMatchTo.id,
              })
            );
            onSubmitCallback();
          } else {
            errorHandler(data);
          }
        },
        errorHandler,
      });
    }

    if (resourceToMatchTo.type === 'transfer') {
      callExternalApiWithLoading({
        externalApiCall: () =>
          matchTransferToInvoices({
            transferId: resourceToMatchTo.id,
            invoiceIds: selectedInvoices.map((invoice) => invoice.id),
          }),
        loadingHandler: setIsLoading,
        responseHandler: ({ data }) => {
          if (data.success) {
            updateInMemoryInvoices((existingInvoices) =>
              updateInMemoryInvoicesWithTransferId({
                invoices: existingInvoices,
                invoicesForMatch: selectedInvoices,
                transferId: resourceToMatchTo.id,
              })
            );
            onSubmitCallback();
          } else {
            errorHandler(data);
          }
        },
        errorHandler,
      });
    }
  };

  return (
    <Popup
      HeaderContent={<Title variant="h3">Invoices to match</Title>}
      width="100%"
      maxWidth="1280px"
      height="840px"
      onClose={onClose}
      FooterContent={
        <Row gap={theme.spacing.m} flex={1}>
          <Row gap={theme.spacing.m}>
            <Button
              disabled={isLoading || differenceAmount !== 0}
              isLoading={isLoading}
              onClick={onSubmitMatchHandler}
            >
              Confirm
            </Button>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
          </Row>
          {!!selectedInvoices.length && (
            <Col alignItems="flex-end">
              <Row alignItems="flex-start" alignSelf="stretch">
                <Subtitle mr variant="bold">
                  Total received:
                </Subtitle>

                <Subtitle variant="bold">
                  {parseIntoCurrencyStringWithSymbol(
                    resourceToMatchTo.amount,
                    currency?.symbol,
                    currency?.precision
                  )}
                </Subtitle>
              </Row>

              <Row alignItems="flex-start" alignSelf="stretch">
                <Subtitle mr variant="bold">
                  Total selected:
                </Subtitle>

                <Subtitle variant="bold">
                  {parseIntoCurrencyStringWithSymbol(
                    selectedInvoicesTotalAmount,
                    currency?.symbol,
                    currency?.precision
                  )}
                </Subtitle>
              </Row>

              <Row alignItems="flex-start" alignSelf="stretch">
                <Row mr gap={theme.spacing.xxs}>
                  <Subtitle variant="bold">Diff:</Subtitle>
                  {differenceAmount !== 0 && (
                    <StaleInfo mode="hover" strategy="fixed" placement="top">
                      <Paragraph color="white">
                        Selected amount must be equal to received amount to
                        confirm
                      </Paragraph>
                    </StaleInfo>
                  )}
                </Row>

                <Subtitle
                  variant="bold"
                  color={differenceAmount === 0 ? 'green' : 'red'}
                >
                  {parseIntoCurrencyStringWithSymbol(
                    differenceAmount,
                    currency?.symbol,
                    currency?.precision
                  )}
                </Subtitle>
              </Row>
            </Col>
          )}
        </Row>
      }
    >
      <Card
        gap={theme.spacing.m}
        padding={theme.spacing.m}
        flexDirection="row"
        mb
      >
        <Col gap={theme.spacing.s}>
          <Row gap={theme.spacing.s}>
            <Paragraph>Currency:</Paragraph>

            <Row gap={theme.spacing.xs} justifyContent="flex-end">
              <Paragraph variant="bold">{resourceToMatchTo.currency}</Paragraph>
              {currency && <Icon icon={currency.countryCode} />}
            </Row>
          </Row>

          <Row gap={theme.spacing.s}>
            <Paragraph>Amount:</Paragraph>

            <Paragraph variant="bold">
              {parseIntoCurrencyStringWithSymbol(
                resourceToMatchTo.amount,
                currency?.symbol
              )}
            </Paragraph>
          </Row>
        </Col>

        <Col gap={theme.spacing.s}>
          <Row gap={theme.spacing.s}>
            <Paragraph>Sender Name:</Paragraph>

            <Row gap={theme.spacing.xs} justifyContent="flex-end">
              <Paragraph variant="bold">
                {resourceToMatchTo.senderName}
              </Paragraph>
            </Row>
          </Row>

          <Row gap={theme.spacing.s}>
            <Paragraph>Payment Date:</Paragraph>

            <Row gap={theme.spacing.xs} justifyContent="flex-end">
              {!!resourceToMatchTo.paymentDate && (
                <Paragraph variant="bold">
                  {dayjs(resourceToMatchTo.paymentDate).format(DATE_FORMAT)}
                </Paragraph>
              )}
            </Row>
          </Row>
        </Col>

        {resourceToMatchTo.paymentRef && (
          <Col gap={theme.spacing.s}>
            <Row gap={theme.spacing.s}>
              <Paragraph>Reference:</Paragraph>

              <Row gap={theme.spacing.xs} justifyContent="flex-end">
                <Paragraph variant="bold">
                  {resourceToMatchTo.paymentRef}
                </Paragraph>
              </Row>
            </Row>
          </Col>
        )}
      </Card>

      {isLoadingFirstInvoicesPage && <Loader size="large" />}

      {!isLoadingFirstInvoicesPage && (
        <Col gap={theme.spacing.m}>
          <TableSearchInput
            placeholder="Search"
            value={searchInputValue}
            onChange={(e) => setSearchInputValue(e.target.value)}
          />

          <InvoicesTableShared
            isVirtualized
            tableRef={tableRef}
            data={invoices as IInvoiceFromSearch[]}
            itemsCount={invoices.length}
            withInfiniteLoading
            loadingThreshold={10}
            hasMoreToLoad={hasMoreToLoad}
            onLoadMoreItems={onLoadMoreItems}
            isLoadingMoreItems={isLoadingMoreInvoices}
            updateInMemoryInvoices={updateInMemoryInvoices}
            onSelectInvoices={setSelectedInvoices}
            manualSortBy
            autoResetSelectedRows={false}
            selectable
            showFooter={false}
            isRowSelectable={() => true}
          />
        </Col>
      )}
    </Popup>
  );
};

export default PopupMatchInvoices;
