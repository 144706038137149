import { action } from 'easy-peasy';
import { PaymentRunsStateModel } from './types';

export const PaymentRunsState: PaymentRunsStateModel = {
  isUpdatingPaymentRun: false,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
};
