import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form7';
import firebase from 'firebase/compat/app';

import {
  StalePopup,
  StaleOverflowWrap,
  StaleOverflowContent,
  Overflow,
  StaleBtnGroup,
  HexagonLine,
  Paragraph,
} from 'components';
import { Notify } from 'utils';
import { ERROR_MESSAGES, VALIDATION_MESSAGES } from 'variables';
import { useRecaptchaVerifier } from 'hooks';
import { Wrapper } from './ChangePassword.styles';
import { useHistory } from 'react-router-dom';
import { useStoreActions } from 'state';
import Button from 'components/shared/Button/Button';
import InputUnconventional from 'components/shared/InputUnconventional/InputUnconventional';

type Inputs = {
  newPassword: string;
  repeatPassword: string;
  currentPassword: string;
  verificationCode: string;
};

const ChangePassword = ({ setShowChangePassword }: any) => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<Inputs>({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const password = watch('newPassword');
  const repeatPassword = watch('repeatPassword');

  const user = firebase.auth().currentUser;
  const { signOut } = useStoreActions((actions) => actions.UserState);

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentPassValid, setIsCurrentPassValid] = useState(true);
  const [isRepeatPassValid, setIsRepeatPassValid] = useState(true);
  const [verificationId, setVerificationId] = useState('');
  const [authResolver, setAuthResolver] = useState<any>(null);
  const [stepOneComplete, setStepOneComplete] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [userPhone, setUserPhone] = useState('');

  const allValid = useMemo(
    () =>
      !!isCurrentPassValid && !!isRepeatPassValid && !!isValid ? true : false,
    [isCurrentPassValid, isRepeatPassValid, isValid]
  );

  const recaptchaContainer = useRecaptchaVerifier('recaptcha');

  const phoneChecker = async (response: any) => {
    if (response.code === 'auth/multi-factor-auth-required') {
      // user has 2FA enabled
      if (response?.resolver?.hints) {
        if (!recaptchaContainer) {
          return;
        }

        const phoneInfoOptions = {
          multiFactorHint: response.resolver.hints[0],
          session: response.resolver.session,
        };
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

        const verificationResp = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaContainer
        );

        setUserPhone(response.resolver.hints[0].phoneNumber);
        setVerificationId(verificationResp);
        setAuthResolver(response.resolver);
        setStepOneComplete(true);
      } else {
        Notify.error(response?.message);
      }
    } else if (response?.code === 'auth/wrong-password') {
      Notify.error('Incorrect current password, please try again');
    } else if (response?.code === 'auth/too-many-requests') {
      history.replace('/reset-password?change_password_error=\\true');
      signOut();
    } else {
      Notify.error(response?.message);
    }
  };

  const onSubmitCode = async (values: any) => {
    const cred = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      values.verificationCode
    );

    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
      cred
    );

    try {
      if (!authResolver) {
        return;
      }

      setIsLoading(true);

      const data = await authResolver.resolveSignIn(multiFactorAssertion);

      if (data?.user) {
        await user?.updatePassword(newPassword);
        setShowChangePassword(false);
        Notify.success('The password was changed successfully');
      }
    } catch (error: any) {
      Notify.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (values: Inputs) => {
    try {
      if (!user?.email) {
        return;
      }

      setIsLoading(true);

      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        values.currentPassword
      );

      setNewPassword(values.newPassword);

      await user
        ?.reauthenticateWithCredential(credential)
        .then()
        .catch((response) => phoneChecker(response));
    } catch (error: any) {
      Notify.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StalePopup
      title="Change password"
      theme="grey"
      width="439px"
      minHeight="auto"
      onClose={setShowChangePassword}
    >
      <Wrapper>
        <StaleOverflowWrap>
          <Overflow overflow={'none'}>
            <StaleOverflowContent>
              {!stepOneComplete && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="field">
                    <Controller
                      name="currentPassword"
                      control={control}
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <InputUnconventional
                          id={name}
                          label="Current password"
                          view="moving"
                          disabled={stepOneComplete}
                          type="password"
                          onChange={(e) => {
                            setIsCurrentPassValid(e.target.value !== '');
                            onChange(e);
                          }}
                          value={value}
                          error={error?.message}
                          autoFocus
                        />
                      )}
                    />
                    <ul className="hexagon-list">
                      <HexagonLine
                        isValid={isCurrentPassValid}
                        text="Current password needs to be entered"
                        hideWhenValid
                      />
                    </ul>
                  </div>
                  <div className="field">
                    <Controller
                      name="newPassword"
                      control={control}
                      rules={{
                        validate: {
                          minLength: (value) => value?.length >= 8,
                          oneUppercase: (value) => /(?=.*[A-Z])/.test(value),
                          oneLowercase: (value) => /(?=.*[a-z])/.test(value),
                          oneNumber: (value) => /(?=.*\d)/.test(value),
                          oneSpecial: (value) =>
                            /(?=.*[-+_!@#$%^&*.,?])/.test(value),
                        },
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <InputUnconventional
                          id={name}
                          label="New password"
                          view="moving"
                          disabled={stepOneComplete}
                          type="password"
                          value={value}
                          error={error?.message}
                          onChange={(e) => {
                            setIsRepeatPassValid(
                              e.target.value === repeatPassword ? true : false
                            );
                            onChange(e);
                          }}
                        />
                      )}
                    />
                    <ul className="hexagon-list">
                      {VALIDATION_MESSAGES.map(({ id, text, validate }) => {
                        return (
                          <HexagonLine
                            key={id}
                            isValid={!!validate(password)}
                            text={text}
                            hideWhenValid
                          />
                        );
                      })}
                    </ul>
                  </div>

                  <div className="field">
                    <Controller
                      name="repeatPassword"
                      control={control}
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <InputUnconventional
                          id={name}
                          label="Confirm new password"
                          disabled={stepOneComplete}
                          view="moving"
                          type="password"
                          value={value}
                          onChange={(e) => {
                            setIsRepeatPassValid(
                              e.target.value === password ? true : false
                            );
                            onChange(e);
                          }}
                          error={error?.message}
                        />
                      )}
                    />
                    <ul className="hexagon-list">
                      <HexagonLine
                        isValid={isRepeatPassValid}
                        text="Passwords have to match"
                        hideWhenValid
                      />
                    </ul>
                  </div>

                  {!stepOneComplete && (
                    <StaleBtnGroup horizontal>
                      <Button
                        type="button"
                        variant="secondary"
                        onClick={() => setShowChangePassword(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        isLoading={isLoading}
                        disabled={!allValid}
                      >
                        Continue
                      </Button>
                    </StaleBtnGroup>
                  )}
                </form>
              )}

              {stepOneComplete && (
                <form onSubmit={handleSubmit(onSubmitCode)}>
                  <div className="field">
                    <Paragraph>
                      We’ve just sent you an SMS with a verification code to{' '}
                      {userPhone}
                    </Paragraph>
                  </div>
                  <div className="field">
                    <Controller
                      name="verificationCode"
                      control={control}
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                        validate: {
                          minLength: (value) => value?.length >= 6,
                        },
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <InputUnconventional
                          id={name}
                          label="Verification code"
                          view="moving"
                          autoFocus
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                        />
                      )}
                    />
                  </div>

                  <StaleBtnGroup>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={!isValid}
                      isLoading={isLoading}
                    >
                      Continue
                    </Button>
                  </StaleBtnGroup>
                </form>
              )}
            </StaleOverflowContent>
          </Overflow>
        </StaleOverflowWrap>
      </Wrapper>
      <div id="recaptcha" />
    </StalePopup>
  );
};

export default ChangePassword;
