import { FC, useMemo } from 'react';
import { Table } from 'components';
import { generateStatementsTableColumns } from './generateTableColumns';

export type MonthForStatementDownload = {
  /** YYYY-MM-DD */
  from: string;
  /** YYYY-MM-DD */
  to: string;
  label: string;
};

interface IOwnProps {
  currency: string;
  /** YYYY-MM-DD */
  data: MonthForStatementDownload[];
}

const StatementsDownloadTable: FC<IOwnProps> = ({ currency, data }) => {
  const columns = useMemo(() => generateStatementsTableColumns(currency), [
    currency,
  ]);

  return (
    <Table<MonthForStatementDownload>
      isVirtualized
      columns={columns}
      data={data}
    />
  );
};

export default StatementsDownloadTable;
