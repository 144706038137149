import { FC, useEffect, useState, useMemo } from 'react';
import {
  StaleCheckboxControlled,
  StaleInputCurrency,
  StaleInputSelect,
  Paragraph,
  PermissionsChecker,
} from 'components';
import { Row } from 'components/shared/Row/Row';
import { Controller, useForm } from 'react-hook-form';
import { EntityInputField } from './RiskSettings.styles';
import { useStoreActions, useStoreState } from 'state';
import { Notify } from 'utils';
import { IEntityRiskSettings, IEntitySettings } from 'types/entities';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import {
  COMMITTED_CURRENCY_QUESTIONS,
  ERROR_MESSAGES,
  FUTURE_CURRENCY_QUESTIONS,
} from 'variables';
import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import { SettingsField } from 'pages/Settings/Settings.styles';
import {
  WhiteContentContainer,
  WhiteFooterContainer,
} from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';
import Button from 'components/shared/Button/Button';
import { errorHandler } from 'utils/errors';

interface Inputs
  extends Omit<
    IEntitySettings,
    'riskSettingCommittedCashFlows' | 'riskSettingExpectedCashFlows'
  > {
  riskSettingCommittedCashFlows?: ISelectItem;
  riskSettingExpectedCashFlows?: ISelectItem;
}

const RiskSettings: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { entityId, entityCurrencyCode } = useStoreState(
    (state) => state.UserState
  );
  const { isEntityRiskSettingsLoading, entityRiskSettings } = useStoreState(
    (state) => state.EntityState
  );
  const { getEntityRiskSettings, updateEntityRiskSettings } = useStoreActions(
    (state) => state.EntityState
  );
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const [isLoading, setIsLoading] = useState(false);
  const defaultCurrency = currencyByCode(entityCurrencyCode);

  const defaultValues = useMemo(() => {
    return {
      ...entityRiskSettings,
      riskSettingCommittedCashFlows: COMMITTED_CURRENCY_QUESTIONS.find(
        (el) => el.value === entityRiskSettings?.riskSettingCommittedCashFlows
      ),
      riskSettingExpectedCashFlows: FUTURE_CURRENCY_QUESTIONS.find(
        (el) => el.value === entityRiskSettings?.riskSettingExpectedCashFlows
      ),
    };
  }, [entityRiskSettings]);

  const { control, handleSubmit, reset } = useForm<Inputs>({
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (entityRiskSettings) {
      reset(defaultValues);
    }
  }, [entityRiskSettings, reset, defaultValues]);

  const onSubmit = async (values: any) => {
    if (!entityId) {
      Notify.error(ERROR_MESSAGES.noEntityId);
      return;
    }

    try {
      setIsLoading(true);

      const riskSettings: IEntityRiskSettings = {
        ...values,
        riskSettingCommittedCashFlows:
          values.riskSettingCommittedCashFlows?.value,
        riskSettingExpectedCashFlows:
          values.riskSettingExpectedCashFlows?.value,
      };

      const { data } = await updateEntityRiskSettings({
        entityId,
        settings: riskSettings,
      });

      if (data?.success) {
        Notify.success('Risk limits settings saved successfully!');
        getEntityRiskSettings(entityId);
      } else {
        errorHandler(data);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form id="entity-form" onSubmit={handleSubmit(onSubmit)}>
      <WhiteContentContainer>
        <EntityInputField>
          <StaleInputCurrency
            id="riskTolerance"
            label="Your risk tolerance"
            view="moving"
            type="text"
            name="riskTolerance"
            control={control}
            currencyPrefix={defaultCurrency?.symbol}
            locked={isEntityRiskSettingsLoading}
          />
        </EntityInputField>

        <SettingsField>
          <StaleCheckboxControlled
            id="shouldSendEmailIfRiskToleranceExceeded"
            name="shouldSendEmailIfRiskToleranceExceeded"
            control={control}
            Label={<>Send an email if the limit is exceeded (coming soon)</>}
            disabled={true}
          />
        </SettingsField>
        <EntityInputField large>
          <Paragraph mb mbValue={theme.spacing.xs}>
            Committed cashflows
          </Paragraph>
          <Controller
            id="riskSettingCommittedCashFlows"
            name="riskSettingCommittedCashFlows"
            control={control}
            render={({ onChange, value, name }) => {
              return (
                <StaleInputSelect
                  id={name}
                  name={name}
                  label="Committed cashflows"
                  view="moving"
                  data={COMMITTED_CURRENCY_QUESTIONS}
                  selected={value}
                  onSelect={onChange}
                  strategy="fixed"
                  disabled={isEntityRiskSettingsLoading}
                />
              );
            }}
          />
        </EntityInputField>

        <EntityInputField large>
          <Paragraph mb mbValue={theme.spacing.xs}>
            Expected cashflows
          </Paragraph>
          <Controller
            id="riskSettingExpectedCashFlows"
            name="riskSettingExpectedCashFlows"
            control={control}
            render={({ onChange, value, name }) => {
              return (
                <StaleInputSelect
                  id={name}
                  name={name}
                  label="Expected cashflows"
                  view="moving"
                  data={FUTURE_CURRENCY_QUESTIONS}
                  selected={value}
                  onSelect={onChange}
                  strategy="fixed"
                  disabled={isEntityRiskSettingsLoading}
                />
              );
            }}
          />
        </EntityInputField>
      </WhiteContentContainer>
      <WhiteFooterContainer>
        <PermissionsChecker action="update" resource="settings_risk">
          <Row columnGap={theme.spacing.m} justifyContent="flex-start">
            <Button
              variant="secondary"
              form="entity-form"
              disabled={isLoading || isEntityRiskSettingsLoading}
              isLoading={isLoading}
            >
              Save
            </Button>

            <Button
              variant="link"
              disabled={isLoading || isEntityRiskSettingsLoading}
              onClick={() => history.push('/app/risk-settings')}
            >
              Manage risk settings
            </Button>
          </Row>
        </PermissionsChecker>
      </WhiteFooterContainer>
    </form>
  );
};

export default RiskSettings;
