import { FC, useState, useEffect } from 'react';
import {
  Title,
  StaleBtnGroup,
  Paragraph,
  StaleSelectLocation,
  Overflow,
} from 'components';
import { useForm, Controller } from 'react-hook-form7';
import { useStoreActions, useStoreState } from 'state';
import { Firebase } from 'services';
import { ENTITY_TYPE, IOwner } from 'types';
import { SignUpContent } from 'pages/CompanyRegistration/components';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { ERROR_MESSAGES } from 'variables';
import Button from 'components/shared/Button/Button';
import { StyledButton } from 'components/shared/Button/Button.styles';
import InputUnconventional from 'components/shared/InputUnconventional/InputUnconventional';

interface IAddressValue {
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  country: string;
  stateOrProvince: string;
}

type Inputs = {
  name: string;
  businessName: string;
  address: {
    label: string;
    value: IAddressValue;
  };
  VAT: string;
  email: string;
};

interface OwnProps {
  onSaveValues: (values: any) => void;
  onContinue: () => void;
  setOnfidoApplicantId: () => void;
  // TODO: add correct interface
  stepOneValues: any;
  stepTwoValues: any;
}

const StepTwo: FC<OwnProps> = ({
  onContinue,
  setOnfidoApplicantId,
  stepOneValues,
  stepTwoValues,
}) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [showAddressDetails, setShowAddressDetails] = useState(false);
  const { user, userEntity } = useStoreState((state) => state.UserState);
  const { getUser, getUserEntity } = useStoreActions(
    (actions) => actions.UserState
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { isValid },
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      ...stepTwoValues,
    },
  });

  const address = watch('address');

  const Line1 = watch('address.value.addressLine1');
  const Line2 = watch('address.value.addressLine2');
  const City = watch('address.value.city');
  const Country = watch('address.value.country');
  const PostalCode = watch('address.value.postalCode');

  useEffect(() => {
    setValue(
      'address.label',
      `${Line1 && `${Line1},`} ${Line2 && `${Line2},`} ${
        PostalCode && `${PostalCode},`
      } ${City && `${City},`} ${Country}`.trim()
    );
  }, [City, Country, Line1, Line2, PostalCode, setValue]);

  const onSubmit = async (values: any) => {
    if (!user || !userEntity) return;

    const { name, businessName, VAT, email } = values;

    const officersData: IOwner[] = [
      {
        address: {
          // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'string'.
          addressLine1: watch('address')?.label,
          ...watch('address')?.value,
          // TODO: remove hardcoded value later
          country: 'gb',
        },
        name,
        email,
        isUser: true,
        isRepresentingEntity: true,
        isPsc: false,
        role: 'owner',
      },
    ];

    setIsLoading(true);

    const response = await Firebase.registerCompany({
      // TODO: remove hardcoded value
      companyCountry: 'gb',
      companyType: ENTITY_TYPE.soleTrader,
      expectedAnnualTurnover: stepOneValues.expectedAnnualTurnover,
      expectedFxTurnoverUpperLimit:
        stepOneValues.expectedMonthlyForeignCurrencyTurnover.value
          .expectedFxTurnoverUpperLimit,
      name: businessName,
      website: 'socialMediaLink hardcoded',
      companyDetails: {
        address: {
          // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'string'.
          addressLine1: watch('address')?.label,
          ...watch('address')?.value,
          // TODO: remove hardcoded value later
          country: 'gb',
        },
        addressCorrespondence: {
          // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'string'.
          addressLine1: watch('address')?.label,
          ...watch('address')?.value,
          // TODO: remove hardcoded value later
          country: 'gb',
        },
        countriesSendingMoneyTo: stepOneValues.countries.map(
          (item: any) => item.value.alpha2
        ),
        natureOfBusiness:
          stepOneValues.businessNature.code === 'other'
            ? stepOneValues.otherNatureOfBusinessLabel
            : stepOneValues.businessNature.label,
        natureOfBusinessCode: stepOneValues.businessNature.code,
        purposeOfAccount: stepOneValues.accountPurpose,
        vat: !!VAT,
        vatNumber: VAT,
      },
      officers: officersData,
    });

    if (response?.success && response.id) {
      // TODO: remove later when we get entity from the registration response
      await getUserEntity({
        entityId: response.id,
      });

      // we need to refetch the user because it has onboarding ID now
      await getUser({
        id: user.id,
      });

      setIsLoading(false);
      // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
      setOnfidoApplicantId(response.onfidoApplicantId);
      onContinue();
      return;
    }

    setIsLoading(false);
  };

  return (
    <>
      <SignUpContent>
        {!isMobile && (
          <StyledButton
            variant="link"
            className="cross"
            onClick={() => history.push('/app/dashboard')}
          >
            <svg width="24" height="24">
              <use xlinkHref="#cross-ico" />
            </svg>
          </StyledButton>
        )}

        <Overflow>
          <div className="block">
            {!isMobile && (
              <StyledButton
                variant="link"
                onClick={history.goBack}
                className="back"
              >
                <svg width="24" height="24">
                  <use xlinkHref="#arrow-left" />
                </svg>
                Back
              </StyledButton>
            )}

            {isMobile ? (
              <Title variant="h5">Please fill your personal entity data</Title>
            ) : (
              <Title>Fill your personal entity data</Title>
            )}

            <form
              id="solo-trader-registration-step-two-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="field">
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: ERROR_MESSAGES.requiredField }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <InputUnconventional
                      id={name}
                      label="Your full name"
                      view="moving"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </div>

              <div className="field">
                <Controller
                  control={control}
                  name="businessName"
                  rules={{ required: ERROR_MESSAGES.requiredField }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <InputUnconventional
                      id={name}
                      label="Trading as (business name)"
                      view="moving"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </div>
              <div className="col">
                <div className="field">
                  <Controller
                    control={control}
                    name="address.label"
                    rules={{ required: ERROR_MESSAGES.requiredField }}
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <StaleSelectLocation
                          id={name}
                          label={value ?? 'Address'}
                          name={name}
                          value={value}
                          onChange={(values: any) => {
                            onChange({
                              label: values.label,
                            });
                            if (values.dataForPaste) {
                              Object.entries(values.dataForPaste).forEach(
                                ([key, value]) => {
                                  setValue(
                                    `address.value.${
                                      key as keyof IAddressValue
                                    }`,
                                    value as string
                                  );
                                  trigger(
                                    `address.value.${
                                      key as keyof IAddressValue
                                    }`
                                  );
                                }
                              );
                            }
                          }}
                        />
                      );
                    }}
                  />
                </div>

                <div
                  className="col"
                  style={{
                    height: showAddressDetails ? 'auto' : 0,
                    overflow: 'hidden',
                  }}
                >
                  <div className="field">
                    <Controller
                      control={control}
                      name="address.value.addressLine1"
                      rules={{ required: ERROR_MESSAGES.requiredField }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <InputUnconventional
                          id={name}
                          label="Address Line 1"
                          view="moving"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          locked={!showAddressDetails}
                        />
                      )}
                    />
                  </div>
                  <div className="field">
                    <Controller
                      control={control}
                      name="address.value.addressLine2"
                      render={({ field: { onChange, value, name } }) => (
                        <InputUnconventional
                          id={name}
                          label="Address Line 2 (optional)"
                          view="moving"
                          value={value}
                          onChange={onChange}
                          locked={!showAddressDetails}
                        />
                      )}
                    />
                  </div>

                  <div className="field">
                    <Controller
                      control={control}
                      name="address.value.city"
                      rules={{ required: ERROR_MESSAGES.requiredField }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <InputUnconventional
                          id={name}
                          label="City"
                          view="moving"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          locked={!showAddressDetails}
                        />
                      )}
                    />
                  </div>

                  <div className="row">
                    <div
                      className="field"
                      style={{ width: 116, marginRight: 16 }}
                    >
                      <Controller
                        control={control}
                        name="address.value.postalCode"
                        rules={{ required: ERROR_MESSAGES.requiredField }}
                        render={({
                          field: { onChange, value, name },
                          fieldState: { error },
                        }) => (
                          <InputUnconventional
                            id={name}
                            label="Post code"
                            view="moving"
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            locked={!showAddressDetails}
                          />
                        )}
                      />
                    </div>
                    <div className="field" style={{ flex: 1 }}>
                      <Controller
                        control={control}
                        name="address.value.stateOrProvince"
                        render={({ field: { onChange, value, name } }) => (
                          <InputUnconventional
                            id={name}
                            label="Region (optional)"
                            view="moving"
                            value={value}
                            onChange={onChange}
                            locked={!showAddressDetails}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <Controller
                      control={control}
                      name="address.value.country"
                      rules={{ required: ERROR_MESSAGES.requiredField }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <InputUnconventional
                          id={name}
                          label="Country"
                          view="moving"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          locked={!showAddressDetails}
                        />
                      )}
                    />
                  </div>
                </div>

                <StyledButton
                  variant="link"
                  type="button"
                  onClick={() => setShowAddressDetails(!showAddressDetails)}
                  style={{
                    textDecoration: 'underline',
                    marginTop: 6,
                    marginBottom: 16,
                  }}
                >
                  <Paragraph>
                    {showAddressDetails
                      ? 'Apply'
                      : address?.label
                      ? 'Edit'
                      : 'Enter manually'}
                  </Paragraph>
                </StyledButton>
              </div>

              <div className="field">
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <InputUnconventional
                      id={name}
                      label="Email"
                      view="moving"
                      type="email"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </div>
              <div className="field">
                <Controller
                  control={control}
                  name="VAT"
                  render={({ field: { onChange, value, name } }) => (
                    <InputUnconventional
                      id={name}
                      label="VAT Number (optional)"
                      view="moving"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </form>

            {!isMobile && (
              <StaleBtnGroup>
                <Button
                  disabled={!isValid}
                  form="solo-trader-registration-step-two-form"
                >
                  Continue
                  {isLoading && (
                    <svg width="16px" height="16px" className="loader">
                      <use xlinkHref="#loader-ico" />
                    </svg>
                  )}
                </Button>
              </StaleBtnGroup>
            )}
          </div>
        </Overflow>
      </SignUpContent>

      {isMobile && (
        <StaleBtnGroup>
          <Button
            disabled={!isValid}
            form="solo-trader-registration-step-two-form"
          >
            Continue
            {isLoading && (
              <svg width="16px" height="16px" className="loader">
                <use xlinkHref="#loader-ico" />
              </svg>
            )}
          </Button>
        </StaleBtnGroup>
      )}
    </>
  );
};

export default StepTwo;
