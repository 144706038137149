import { action, computed, thunk } from 'easy-peasy';

import { subscribeToHedgeFlowsBalances } from 'services/balances';
import { BalancesStateModel } from './types';

export const BalancesState: BalancesStateModel = {
  balances: [],
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  subscribeToBalances: thunk(({ setState }, { entityId }) => {
    const unsubscribeFunc = subscribeToHedgeFlowsBalances({
      entityId,
      callback: (balances) => {
        if (balances) {
          const sortedBalances = balances.sort((el) => {
            if (
              el.currency === 'GBP' ||
              el.currency === 'USD' ||
              el.currency === 'EUR'
            ) {
              return -1;
            } else {
              return 1;
            }
          });

          setState(['balances', sortedBalances]);
        }
      },
    });

    return unsubscribeFunc;
  }),
  balanceByCurrencyCode: computed(
    [(state) => state.balances],
    (balances) => (currencyCode) =>
      balances.find((balance) => balance.currency === currencyCode)
  ),
};
