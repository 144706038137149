import dayjs from 'dayjs';
import { InlineLoader, Loader, Row, StaleInputSelect } from 'components/shared';
import { FC, useEffect, useMemo, useState } from 'react';
import { getWalletCurrencies } from 'services/transactions';
import { errorHandler } from 'utils/errors';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import StatementsDownloadTable from './components/StatementsDownloadTable';
import InternalLink from 'components/shared/InternalLink/InternalLink.styles';

const getLast12MonthsSelectionData = () => {
  const dates = Array.from({ length: 12 })
    .fill(0)
    .map((_, index) => dayjs().subtract(index, 'month').format('YYYY-MM'));

  const monthSelectionData = dates.map((date) => ({
    id: date,
    name: dayjs(date).format('MMMM YYYY'),
    value: dayjs(date).format('MMMM YYYY'),
  }));

  return monthSelectionData;
};

const getMonthsAsSelectionData = () =>
  getLast12MonthsSelectionData().filter((month) =>
    dayjs(month.id).isAfter(dayjs('2024-12-01').endOf('month'))
  );

const getMonthsAsDataForTable = (months: { id: string }[]) => {
  const monthSelectionData = months.map(({ id }) => ({
    id: id,
    from: dayjs(id).startOf('month').format('YYYY-MM-DD'),
    to: dayjs(id).endOf('month').format('YYYY-MM-DD'),
    label: dayjs(id).format('MMMM YYYY'),
  }));

  return monthSelectionData;
};

const StatementsDownload: FC = () => {
  const theme = useTheme();

  const { currency, setUrlValue } = useUrlValues('currency');
  const [selectedMonth, setSelectedMonth] = useState<{
    id: string;
    name: string;
  }>();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const [walletCurrencies, setWalletCurrencies] = useState<string[]>([]);

  useEffect(() => {
    getWalletCurrencies()
      .then((res) => setWalletCurrencies(res.data.data ?? []))
      .catch(errorHandler);
  }, []);

  useEffect(() => {
    if (walletCurrencies.length && !currency) {
      setUrlValue({ currency: walletCurrencies[0] });
    }
  }, [walletCurrencies, setUrlValue, currency]);

  const monthSelectionData = getMonthsAsSelectionData();
  const monthSelectionDataForTable = getMonthsAsDataForTable(
    monthSelectionData
  );

  useEffect(() => {
    if (!selectedMonth && monthSelectionData.length) {
      setSelectedMonth(monthSelectionData[0]);
    }
  }, [selectedMonth, setSelectedMonth, monthSelectionData]);

  const currenciesSelectData = useMemo(
    () =>
      walletCurrencies.map((currency) => ({
        id: currency,
        name: currency,
        value: currency,
        label: currency,
        icon: currencyByCode(currency)?.countryCode,
      })),
    [currencyByCode, walletCurrencies]
  );

  if (!walletCurrencies.length) {
    return <Loader size="large" />;
  }

  return (
    <>
      <Row mb mbValue={theme.spacing.m} justifyContent="space-between">
        <StaleInputSelect
          selected={currency}
          data={currenciesSelectData}
          onSelect={(item) => setUrlValue({ currency: item.value })}
          id="statements-currency"
          inputHeight="32px"
          style={{ minWidth: '132px' }}
        />
        <InternalLink to="/app/statements">Go Back</InternalLink>
      </Row>

      {!currency && <InlineLoader />}

      {currency && (
        <StatementsDownloadTable
          currency={currency}
          data={monthSelectionDataForTable}
        />
      )}
    </>
  );
};

export default StatementsDownload;
