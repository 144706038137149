import { Button, Col, Paragraph } from 'components/shared';
import { Component, ReactNode } from 'react';
import { log } from 'utils';

export interface WithErrorBoundaryProps {
  handleError?: (error: Error) => void;
}
interface ErrorBoundaryWrapperProps {
  children: (handleError: WithErrorBoundaryProps['handleError']) => ReactNode;
}

interface ErrorBoundaryWrapperState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundaryWrapper extends Component<
  ErrorBoundaryWrapperProps,
  ErrorBoundaryWrapperState
> {
  constructor(props: ErrorBoundaryWrapperProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error || null };
  }

  componentDidCatch(error: Error, info: any) {
    log({
      type: 'error',
      triggeredBy: 'ErrorBoundaryWrapper',
      message: error.message,
      uiComponentStack: info?.componentStack,
      url: window.location.href,
    });
  }

  resetError = () => {
    this.setState({ hasError: false, error: null });
  };

  handleError = (error: Error) => {
    this.setState({ hasError: true, error: error });
    this.componentDidCatch(error, { componentStack: '' });
  };

  render() {
    if (this.state.hasError) {
      return (
        <Col
          flex={1}
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
        >
          <Paragraph textAlign="center">
            Error: {`${this.state.error?.message || 'Something went wrong'}`}.
          </Paragraph>
          <Button variant="link" onClick={this.resetError}>
            Refresh
          </Button>
        </Col>
      );
    }

    return this.props.children(this.handleError);
  }
}

export default ErrorBoundaryWrapper;
