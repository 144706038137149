import React, { FC, useState, useEffect } from 'react';

import { IMaskInput } from 'react-imask';
import { Wrapper } from './StaleInputCurrency.styles';

interface IOwnProps {
  id: string;
  type?: HTMLInputElement['type'];
  label?: string;
  view?: 'static' | 'moving';
  icon?: string;
  onActionIcon?: () => any;
  locked?: boolean;
  currencyPrefix?: string;
  error?: string;
  wrapperStyle?: React.CSSProperties;
  value: any;
  onChange: (value: any) => void;
  ref: any;
}

const InputCurrencyUncontrolled: FC<IOwnProps> = ({
  id,
  label = '',
  view = 'static',
  type = 'text',
  icon = '',
  onActionIcon = (e: any) => e,
  locked = false,
  currencyPrefix = '',
  error = '',
  wrapperStyle = {},
  value,
  onChange,
  ref,
}) => {
  const [focused, setFocused] = useState(false);
  const [filled, setFilled] = useState(false);

  useEffect(() => {
    setFilled(!!value);
  }, [value]);

  return (
    <Wrapper
      className={`${view}`}
      icon={!!icon}
      locked={locked}
      error={!!error}
      currencyPrefix={!!currencyPrefix}
      filled={filled}
      focused={focused}
      style={wrapperStyle}
    >
      <div className="wrap">
        {label && (
          <label className="label" htmlFor={id}>
            {label}
          </label>
        )}

        {!!currencyPrefix && <div className="prefix">{currencyPrefix}</div>}

        <IMaskInput
          inputMode="decimal"
          className="input"
          value={value === 0 ? '' : value}
          mask={Number}
          radix="."
          scale={2}
          thousandsSeparator=","
          ref={ref}
          id={id}
          type={type}
          placeholder={view === 'static' ? label : ''}
          autoComplete="new-password"
          unmask="typed"
          onAccept={(value) => {
            onChange(value);
          }}
          disabled={locked}
          onFocus={() => setFocused(true)}
          onBlur={(e) => {
            setFocused(false);
            setFilled(!!e.target.value.length && !!label);
          }}
        />
      </div>

      {icon && onActionIcon && (
        <button type="button" className="icon" onClick={onActionIcon}>
          <svg width="24px" height="24px">
            <use xlinkHref={`#${icon}`} />
          </svg>
        </button>
      )}

      {icon && !onActionIcon && (
        <svg className="icon" width="24px" height="24px">
          <use xlinkHref={`#${icon}`} />
        </svg>
      )}

      {error && <span className="error">{error}</span>}
    </Wrapper>
  );
};

export default InputCurrencyUncontrolled;
