import { useEffect } from 'react';
import auth from 'services/auth';
import {
  subscribeToEntity,
  subscribeToUserEntityIntegrationsSummary,
} from 'services/firebase/user';
import { useStoreActions, useStoreState } from 'state';
import { Firebase } from 'services';
import { IIntegrationStatusSummary } from 'types/integrations';
import useOnIntegrationConnection from 'hooks/useOnIntegrationConnection';
import { refreshCashflowSummaries } from 'services/cashflows';
import { errorHandler } from 'utils/errors';
import useEntityDataStatuses from 'hooks/useEntityDataStatuses';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import { updateLastPeriodReportsIfNeeded } from 'services/firebase/analysis';

const setVh = () => {
  document.documentElement.style.setProperty(
    '--vh',
    `${window.innerHeight * 0.01}px`
  );
};

const useBootstrap = () => {
  const { isIntegrated } = useIntegrationEngine();
  useOnIntegrationConnection();
  const {
    getEntityCashflowsSettings,
    getEntityPayoutSettings,
    getEntityRiskSettings,
  } = useStoreActions(({ EntityState }) => EntityState);
  const { entityCurrencyCode, entityId, userId } = useStoreState(
    ({ UserState }) => UserState
  );
  const {
    getEntityAccountDetails,
    getUser,
    getUserPermissionsOnEntity,
    setState: setUserState,
    setUser,
    setUserAuth,
    setUserEntity,
    setUserEntityIntegrations,
    getEntityUsers,
    getEntityInvites,
    getIntegrationEngineSettings,
  } = useStoreActions(({ UserState }) => UserState);
  const { getCurrencies, subscribeToFollowedCurrencies } = useStoreActions(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { subscribeToTransfers } = useStoreActions(
    ({ TransfersState }) => TransfersState
  );
  const { subscribeToRateContracts } = useStoreActions(
    ({ RateContractsState }) => RateContractsState
  );
  const { subscribeToBulkPayments } = useStoreActions(
    ({ BulkPaymentsState }) => BulkPaymentsState
  );
  const { subscribeToRecipients } = useStoreActions(
    ({ RecipientsState }) => RecipientsState
  );
  const { subscribeToExternalHedges } = useStoreActions(
    ({ ExternalHedgesState }) => ExternalHedgesState
  );
  const {
    subscribeToCashflowsRisks,
    refreshCashflowsRisksData,
    getRiskContribution,
  } = useStoreActions(({ ReferenceDataState }) => ReferenceDataState);
  const {
    getSystemVariables,
    getCountries,
    getSwiftFees,
    getFundingAccounts,
    setState: setReferenceDataState,
  } = useStoreActions(({ ReferenceDataState }) => ReferenceDataState);
  const { subscribeToBalances } = useStoreActions(
    ({ BalancesState }) => BalancesState
  );
  const { checkForInvoices } = useStoreActions(
    ({ InvoicesState }) => InvoicesState
  );
  const { getFeatureFlags } = useStoreActions(
    ({ FeatureFlagsState }) => FeatureFlagsState
  );

  useEffect(() => {
    if (entityId && entityCurrencyCode) {
      refreshCashflowsRisksData({
        entityId,
        sellCurrency: entityCurrencyCode,
      });
      getFundingAccounts(entityId);
    }
  }, [
    entityCurrencyCode,
    entityId,
    refreshCashflowsRisksData,
    getFundingAccounts,
  ]);

  useEffect(() => {
    getSystemVariables();
    getCurrencies();
    getSwiftFees();
    getCountries();
    getRiskContribution();
  }, [
    getSystemVariables,
    getCurrencies,
    getSwiftFees,
    getCountries,
    userId,
    getRiskContribution,
    checkForInvoices,
  ]);

  useEffect(() => {
    setVh();
    window.addEventListener('resize', setVh);
    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  useEffect(() => {
    let unsubscribeTransfers: any;
    let unsubscribeRateContracts: any;
    let unsubscribeRecipients: any;
    let unsubscribeFromBulkPayments: any;
    let unsubscribeFromCashflowRisks: any;
    let unsubscribeFromPurchaseOrders: any;
    let unsubscribeFromIntegrationsSummary: any;
    let unsubscribeFromEntity: any;
    let unsubscribeFromBalances: any;
    let unsubscribeFromExternalHedges: any;

    if (entityId) {
      updateLastPeriodReportsIfNeeded().catch(errorHandler);
      // refetch feature flags when entity id is there
      getFeatureFlags();
      getEntityCashflowsSettings(entityId);
      getEntityPayoutSettings(entityId);
      getEntityRiskSettings(entityId);
      getEntityAccountDetails({ entityId });
      getEntityUsers({ entityId });
      getEntityInvites({ entityId });
      refreshCashflowSummaries(entityId);
      checkForInvoices();
      getUserPermissionsOnEntity();
      unsubscribeTransfers = subscribeToTransfers({ entityId });
      unsubscribeRateContracts = subscribeToRateContracts({ entityId });
      unsubscribeRecipients = subscribeToRecipients({ entityId });
      unsubscribeFromCashflowRisks = subscribeToCashflowsRisks({ entityId });
      unsubscribeFromExternalHedges = subscribeToExternalHedges({
        entityId,
      });
      unsubscribeFromBulkPayments = subscribeToBulkPayments({ entityId });
      unsubscribeFromEntity = subscribeToEntity({
        entityId,
        callback: (entity) => {
          if (entity) {
            setUserEntity(entity);
          }
        },
      });
      unsubscribeFromIntegrationsSummary = subscribeToUserEntityIntegrationsSummary(
        {
          entityId,
          callback: (integrationSummary: IIntegrationStatusSummary | null) => {
            setUserEntityIntegrations(integrationSummary);
          },
        }
      );

      unsubscribeFromBalances = subscribeToBalances({ entityId });
    } else {
      // feature flags required in any case, but we do not want to fetch them twice
      getFeatureFlags();
    }

    return () => {
      unsubscribeTransfers?.();
      unsubscribeRateContracts?.();
      unsubscribeRecipients?.();
      unsubscribeFromBulkPayments?.();
      unsubscribeFromCashflowRisks?.();
      unsubscribeFromPurchaseOrders?.();
      unsubscribeFromIntegrationsSummary?.();
      unsubscribeFromEntity?.();
      unsubscribeFromBalances?.();
      unsubscribeFromExternalHedges?.();
    };
  }, [
    checkForInvoices,
    entityId,
    getEntityAccountDetails,
    getUserPermissionsOnEntity,
    setUserEntity,
    setUserEntityIntegrations,
    subscribeToBalances,
    subscribeToBulkPayments,
    subscribeToCashflowsRisks,
    subscribeToExternalHedges,
    subscribeToRateContracts,
    subscribeToRecipients,
    subscribeToTransfers,
    getFeatureFlags,
    getEntityUsers,
    getEntityInvites,
    getEntityCashflowsSettings,
    getEntityPayoutSettings,
    getEntityRiskSettings,
  ]);

  useEffect(() => {
    let unsubscribeFollowedCurrencies: any;

    if (userId) {
      unsubscribeFollowedCurrencies = subscribeToFollowedCurrencies({
        uid: userId,
      });
    }

    return () => {
      if (unsubscribeFollowedCurrencies) {
        unsubscribeFollowedCurrencies();
      }
    };
  }, [userId, subscribeToFollowedCurrencies]);

  useEffect(() => {
    // Subscribe to auth changes
    const authListener = auth.onAuthStateChanged(async (userFromAuth) => {
      try {
        if (userFromAuth) {
          Firebase.registerUserConnection({
            userId: userFromAuth.uid,
          });

          const { uid } = userFromAuth;

          await getUser({ id: uid });
          setUserAuth(userFromAuth);
        } else {
          setUser(null);
          setUserAuth(null);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setUserState(['isAuthLoading', false]);
      }
    });

    return () => {
      authListener();
    };
  }, [
    setUser,
    setUserAuth,
    getUser,
    setReferenceDataState,
    setUserState,
    entityId,
  ]);

  useEffect(() => {
    if (isIntegrated) {
      getIntegrationEngineSettings();
    }
  }, [isIntegrated, getIntegrationEngineSettings]);

  useEntityDataStatuses();
};

export default useBootstrap;
