export enum RATE_TYPE {
  market = 'market',
  prebooked = 'prebook',
  noRate = 'noRate',
  forwardRate = 'forwardRate',
}

export interface IRate {
  rate: number;
  conversionFeeRate: number;
}

export interface IRateFromCache extends IRate {
  currencyPair: string;
  asAt: string;
}

export interface IForwardRateAndFees extends IRate {
  ccyPair: string;
  fwdDate: string;
  fwdDateAdjusted: string;
  spotRate: number;
  flexFeeRate: number;
  bookingFeeRate: number;
  debug?: any;
}

export interface IForwardRateAndFeesResponse {
  success: boolean;
  data?: IForwardRateAndFees;
  error?: string;
}

interface CurrencyRateValues {
  currencyCode: string;
  rate: number | undefined;
}

export type CurrencyRate = PromiseSettledResult<CurrencyRateValues>;

export type CurrencyRateFulfilled = PromiseFulfilledResult<CurrencyRateValues>;
