import { useEffect, useState } from 'react';
import { useStoreState } from 'state';

const useRiskRating = (cashflowAtRisk?: number) => {
  const { entityRiskSettings } = useStoreState(
    ({ EntityState }) => EntityState
  );
  const [riskRating, setRiskRating] = useState<number>();

  useEffect(() => {
    if (cashflowAtRisk !== undefined) {
      const riskToleranceDivider =
        (entityRiskSettings?.riskTolerance || 10000) / 5;
      const ratingLevel =
        Math.floor(Math.abs(cashflowAtRisk) / riskToleranceDivider) + 1;

      setRiskRating(Math.min(5, ratingLevel));
    }
  }, [cashflowAtRisk, entityRiskSettings?.riskTolerance]);

  return { riskRating };
};

export default useRiskRating;
