import { IResourceToMatchTo } from 'components/shared/PopupMatchInvoices/PopupMatchInvoices';
import { IWalletTransaction } from '../../types';
import { getTransferInfo } from 'services/bff';

export const walletTransactionToResourceToMatchTo = async (
  walletTransaction: IWalletTransaction
): Promise<IResourceToMatchTo> => {
  // Some wallet transactions have payment fees which we want to exclude from the amount to match
  const paymentFeesAmount = walletTransaction.writableResources
    .filter((writableResource) => writableResource.type === 'paymentFee')
    .reduce((acc, fee) => acc + (fee.amount ?? 0), 0);

  let currency = walletTransaction.currency;
  let amount = Math.abs(walletTransaction.amount) - paymentFeesAmount;

  if (walletTransaction.type === 'Transfer out') {
    const { data } = await getTransferInfo(walletTransaction.sourceId);

    if (data.data?.transfer) {
      if (data.data.transfer.sellCurrency !== data.data.transfer.buyCurrency) {
        currency = data.data.transfer.buyCurrency;
        amount = data.data.transfer.buyAmount;
      }
    }
  }

  return {
    type: walletTransaction.type === 'Transfer in' ? 'funding' : 'transfer',
    amount,
    id: walletTransaction.sourceId,
    currency,
    senderName: walletTransaction.description,
    paymentDate: walletTransaction.settledOnTimestamp,
    paymentRef: walletTransaction.reference,
  };
};
