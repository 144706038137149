import { FC, useMemo } from 'react';
import { useStoreState } from 'state';
import PaymentsRunTableTile from 'components/shared/PaymentsRunTableTile/PaymentsRunTableTile';
import { Section } from 'components/shared/Section/Section.styles';
import SummaryTable from '../../../../components/shared/SummaryTable/SummaryTable';
import Accounts from '../Accounts/Accounts';
import CashNeeds from 'components/shared/CashNeeds/CashNeeds';
import useDeviceWidth from 'hooks/useDeviceWidth';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper/ErrorBoundaryWrapper';

const FinanceDashboard: FC = () => {
  const { isMobile } = useDeviceWidth();
  const userRoles = useStoreState(({ UserState }) => UserState.userRoles);

  const showSummaryTable = userRoles.some(
    (role) => role.role === 'admin' || role.role === 'risk_manager'
  );

  return (
    <>
      <Section>
        {useMemo(
          () => (
            <ErrorBoundaryWrapper>
              {(handleError) => <CashNeeds handleError={handleError} />}
            </ErrorBoundaryWrapper>
          ),
          []
        )}
      </Section>
      <Section>
        {showSummaryTable && !isMobile && (
          /** Show summary table only on desktop */
          <SummaryTable />
        )}
        {!showSummaryTable && <Accounts />}
      </Section>

      <Section>
        <PaymentsRunTableTile showLimit={5} showMore />
      </Section>
    </>
  );
};

export default FinanceDashboard;
