import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FollowedCurrencyContentWrapper,
  FollowedCurrencyWrapper,
  RateWrapper,
  HexagonsWrapper,
} from './FollowedCurrency.styles';
import {
  Loader,
  Icon,
  Row,
  Title,
  Col,
  TextHint,
  InlineLoader,
} from 'components';
import { IFollowedCurrencyPair } from 'types';
import {
  getCountryCodeByCurrency,
  getRateByRateType,
  parseRateWithPrecision,
  roundToPrecision,
} from 'utils';
import { useStoreState } from 'state';
import useCurrencyRate from 'hooks/useCurrencyRate';
import { useTheme } from 'styled-components';
import {
  getCurrencyExchangePageLink,
  getPrebookPageLink,
  getTransfersPageLink,
} from 'utils/links';
import Button from 'components/shared/Button/Button';
import { getRatings } from 'services/firebase';
import HexagonRating from 'components/shared/HexagonRating/HexagonRating';
import { getHexagonsStatus } from 'components/shared/HexagonRating/utils';

interface IOwnProps {
  currency: IFollowedCurrencyPair;
  onEditCurrency: any;
  zIndex: number;
  showTransferButton?: boolean;
}

const FollowedCurrency: FC<IOwnProps> = ({
  currency,
  onEditCurrency,
  zIndex,
  showTransferButton,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { currencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { rate, isLoading: isRateLoading } = useCurrencyRate({
    sellCurrency: currency.sellCurrency,
    buyCurrency: currency.buyCurrency,
    predicate: !!currency,
    withCancel: false,
    useCachedRate: true,
  });
  const [rateRating, setRateRating] = useState<number>();
  const [rateRatingText, setRateRatingText] = useState('');

  const rateToUse = useMemo(() => {
    if (currency.rateType) {
      const rateByRateType = getRateByRateType(
        rate,
        currency.rateType,
        !!currency.invertRate
      );
      return rateByRateType ? roundToPrecision(rateByRateType, 4) : null;
    }
    return null;
  }, [rate, currency]);

  useEffect(() => {
    if (rate) {
      const { buyCurrency, sellCurrency } = currency;
      const payload = {
        buyCurrency,
        sellCurrency,
        rate:
          getRateByRateType(
            rate,
            currency.rateType ?? 'pay',
            !!currency.invertRate
          ) || 1,
      };

      if (currency.rateType === 'receive') {
        payload.buyCurrency = sellCurrency;
        payload.sellCurrency = buyCurrency;
        payload.rate = getRateByRateType(rate, 'mid', true) || 1;
      }

      getRatings(payload).then((resp) => {
        setRateRating(resp?.data?.rateRating);
        setRateRatingText(resp?.data.rateRatingText);
      });
    }
  }, [currency, rate]);

  const allowedToExchangeAndTransfer = currencyByCode(
    currency.rateType !== 'receive'
      ? currency.sellCurrency
      : currency.buyCurrency
  )?.sell;

  const allowedToPrebook = currencyByCode(
    currency.rateType !== 'receive'
      ? currency.sellCurrency
      : currency.buyCurrency
  )?.prebookable;

  const exchangeAndPrebookPredefinedCurrencies = {
    ...(currency.rateType !== 'receive'
      ? {
          predefinedBuyCurrency: currency.buyCurrency,
          predefinedSellCurrency: currency.sellCurrency,
        }
      : {
          predefinedSellCurrency: currency.buyCurrency,
          predefinedBuyCurrency: currency.sellCurrency,
        }),
  };

  return (
    <FollowedCurrencyWrapper
      loading={!rateToUse}
      id={`currency-card-${currency.id}`}
      style={{ zIndex }}
    >
      <FollowedCurrencyContentWrapper>
        <Row>
          <Row>
            <Icon
              icon={getCountryCodeByCurrency(currency.buyCurrency, currencies)}
            />
            <Title variant="h3" ml>
              {currency.buyCurrency}
            </Title>
          </Row>

          <Row>
            <Button
              variant="link"
              icon="edit-ico"
              onClick={() => onEditCurrency(currency)}
            />
          </Row>
        </Row>

        <RateWrapper>
          <Col flex={1.25} style={{ minHeight: 72 }}>
            {isRateLoading ? (
              <Loader
                size="medium"
                style={{ alignSelf: 'center', margin: 'auto 0' }}
              />
            ) : (
              <>
                <TextHint>
                  1{' '}
                  {currency.invertRate
                    ? currency.buyCurrency
                    : currency.sellCurrency}{' '}
                  ={' '}
                </TextHint>

                <Row alignItems="baseline" justifyContent="flex-start">
                  <Title
                    style={{ whiteSpace: 'break-spaces' }}
                    variant="h2"
                  >{`${parseRateWithPrecision(rateToUse)} `}</Title>

                  <TextHint>
                    {currency.invertRate
                      ? currency.sellCurrency
                      : currency.buyCurrency}
                  </TextHint>
                </Row>

                <TextHint>
                  {currency.targetRate
                    ? `Your target: ${parseRateWithPrecision(
                        currency.targetRate
                      )}`
                    : ''}
                </TextHint>
              </>
            )}
          </Col>

          <HexagonsWrapper>
            <Row flex={1} alignSelf="stretch" justifyContent="flex-end">
              {typeof rateRating === 'undefined' ? (
                <InlineLoader />
              ) : (
                <HexagonRating
                  rating={rateRating}
                  status={getHexagonsStatus(true, 'desc', rateRating)}
                  title="Rate"
                  description="We tell you how good rates vs the recent history based on your net exposures"
                  statusText={rateRatingText}
                />
              )}
            </Row>
          </HexagonsWrapper>
        </RateWrapper>

        <Row mt mtValue={theme.spacing.l}>
          <Button
            mr
            disabled={!allowedToExchangeAndTransfer}
            onClick={() => {
              history.push(
                getCurrencyExchangePageLink({
                  ...exchangeAndPrebookPredefinedCurrencies,
                  step: '1',
                })
              );
            }}
          >
            Exchange now
          </Button>

          {!showTransferButton && (
            <Button
              variant="secondary"
              disabled={!allowedToPrebook}
              onClick={() => {
                history.push(
                  getPrebookPageLink(exchangeAndPrebookPredefinedCurrencies)
                );
              }}
            >
              Prebook FX
            </Button>
          )}

          {showTransferButton && (
            <Button
              variant="secondary"
              disabled={!allowedToExchangeAndTransfer}
              onClick={() => {
                history.push(
                  getTransfersPageLink(exchangeAndPrebookPredefinedCurrencies)
                );
              }}
            >
              FX Transfer
            </Button>
          )}
        </Row>
      </FollowedCurrencyContentWrapper>
    </FollowedCurrencyWrapper>
  );
};

export default FollowedCurrency;
