import { FC, useEffect, useState } from 'react';

import { Paragraph, Col, Title, Loader, StaleInputRadioNew } from 'components';
import { Notify } from 'utils';
import { useTheme } from 'styled-components';
import { useStoreState, useStoreActions } from 'state';
import { useHistory } from 'react-router';
import { ERROR_MESSAGES, FUTURE_CURRENCY_QUESTIONS } from 'variables';
import Button from 'components/shared/Button/Button';
import { getRiskSettingsPageLink } from 'utils/links';
import { IEntityRiskSettings } from 'types';
import { errorHandler } from 'utils/errors';

const StepThree: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { entityId } = useStoreState((state) => state.UserState);
  const { isEntityRiskSettingsLoading, entityRiskSettings } = useStoreState(
    (state) => state.EntityState
  );
  const { getEntityRiskSettings, updateEntityRiskSettings } = useStoreActions(
    (state) => state.EntityState
  );
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState('');

  const onContinue = async (step: string) => {
    if (!entityId) {
      Notify.error(ERROR_MESSAGES.noEntityId);
      return;
    }

    try {
      setIsLoading(true);

      const { data } = await updateEntityRiskSettings({
        entityId,
        settings: {
          riskSettingExpectedCashFlows: question as IEntityRiskSettings['riskSettingExpectedCashFlows'],
        },
      });

      if (data?.success) {
        getEntityRiskSettings(entityId);
        history.push(getRiskSettingsPageLink({ step }));
      } else {
        errorHandler(data);
        setIsLoading(false);
      }
    } catch (error: any) {
      errorHandler(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (entityRiskSettings?.riskSettingExpectedCashFlows) {
      setQuestion(entityRiskSettings.riskSettingExpectedCashFlows);
    }
  }, [entityRiskSettings]);

  return isLoading ? (
    <Loader size="large" />
  ) : (
    <>
      <Title mb mbValue={theme.spacing.xxl} variant="h1">
        Expected cashflows
      </Title>

      <Col justifyContent="center">
        <Paragraph mb mbValue={theme.spacing.xl}>
          Your finances may be exposed to currency fluctuations even before
          invoices are posted. Any foreign currency sales or purchase order
          exposes your profit margins to currency effects.
        </Paragraph>

        <Title variant="h3" mb mbValue={theme.spacing.xxl}>
          How do you want to manage future currency risks?
        </Title>
        <form>
          <Col rowGap={theme.spacing.m} mb mbValue={theme.spacing.xxxl}>
            {FUTURE_CURRENCY_QUESTIONS.map(({ id, value, name }) => (
              <StaleInputRadioNew
                key={id}
                id={value}
                label={name}
                checked={question === value}
                onChange={() => setQuestion(value)}
              />
            ))}
          </Col>

          <Col gap={theme.spacing.xl} justifyContent="center">
            <Button
              disabled={isLoading || !question || isEntityRiskSettingsLoading}
              isLoading={isLoading}
              onClick={() => onContinue('4')}
              variant="secondary"
            >
              Add Hedge Ratios
            </Button>

            <Button
              disabled={isLoading || !question || isEntityRiskSettingsLoading}
              isLoading={isLoading}
              onClick={() => onContinue('5')}
            >
              Continue
            </Button>
          </Col>
        </form>
      </Col>
    </>
  );
};

export default StepThree;
