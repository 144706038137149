import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import { IEntityPayoutSettings } from 'types';

export const PAYOUT_FREQUENCY_DATA: ISelectItem<
  IEntityPayoutSettings['payoutFrequency']
>[] = [
  {
    name: 'Weekly',
    id: 'Weekly',
    value: 'Weekly',
  },
  {
    name: 'Bi-weekly',
    id: 'Bi-weekly',
    value: 'Bi-weekly',
  },
  {
    name: 'Monthly',
    id: 'Monthly',
    value: 'Monthly',
  },
];

export const WEEKDAY_DATA: ISelectItem<number>[] = [
  {
    name: 'Monday',
    id: 'Monday',
    value: 1,
  },
  {
    name: 'Tuesday',
    id: 'Tuesday',
    value: 2,
  },
  {
    name: 'Wednesday',
    id: 'Wednesday',
    value: 3,
  },
  {
    name: 'Thursday',
    id: 'Thursday',
    value: 4,
  },
  {
    name: 'Friday',
    id: 'Friday',
    value: 5,
  },
];
