import { FC, useState, useMemo, useEffect } from 'react';
import { Title, StaleInputSelect, Paragraph } from 'components';
import { Row } from 'components/shared/Row/Row';
import { Col } from 'components/shared/Col/Col';
import { useTheme } from 'styled-components';
import {
  WhiteContentContainer,
  WhiteFooterContainer,
} from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';
import Button from 'components/shared/Button/Button';
import { Controller, useForm } from 'react-hook-form7';
import {
  generateLabelText,
  generatePayoutContactData,
  generatePayoutSettingsToSave,
  populateContactsForDropdown,
} from './utils';
import {
  IContact,
  ICurrency,
  IEntityPayoutSettings,
  Nullable,
  RECIPIENT_STATUS,
  TRANSFER_TYPE,
} from 'types';
import Field from 'components/shared/Field/Field.styles';
import { ERROR_MESSAGES } from 'variables';
import { useStoreState, useStoreActions } from 'state';
import { hasDuplicatesInArray, Notify } from 'utils';
import { errorHandler } from 'utils/errors';
import AddContactForm from 'components/shared/AddContactForm/AddContactForm';
import { Inputs, IPayoutContactData } from './types';
import { PayoutContactsFieldsWrapper } from './PayoutSettings.styles';
import InputCurrencyUncontrolled from 'components/shared/StaleInputCurrency/InputCurrencyUncontrolled';
import { PAYOUT_FREQUENCY_DATA, WEEKDAY_DATA } from './consts';

const PayoutSettings: FC = () => {
  const theme = useTheme();
  const { entityId, entityCurrencyCode } = useStoreState(
    (state) => state.UserState
  );
  const { isEntityPayoutSettingsLoading, entityPayoutSettings } = useStoreState(
    ({ EntityState }) => EntityState
  );
  const { sellCurrencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { recipients, recipientById } = useStoreState(
    (state) => state.RecipientsState
  );
  const {
    getEntityPayoutSettings,
    updateEntityPayoutSettings,
  } = useStoreActions(({ EntityState }) => EntityState);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recipientDataForAdd, setRecipientDataForAdd] = useState<
    Nullable<{ currency: ICurrency; contactRowID: string }>
  >(null);
  const [formPayoutContactsNames, setFormPayoutContactsNames] = useState<
    string[]
  >([]);
  const [payoutContactsSearchValue, setPayoutContactsSearchValue] = useState(
    ''
  );
  const isLoading = isSubmitting || isEntityPayoutSettingsLoading;

  const payoutContactsGroupedByCurrency = useMemo(
    () =>
      recipients.reduce<Record<string, IPayoutContactData[]>>(
        (total, contact) => {
          const currencyCode = contact.currency;

          if (!contact.enabled || contact.status === RECIPIENT_STATUS.draft) {
            return total;
          }

          if (Array.isArray(total[currencyCode])) {
            total[currencyCode].push(generatePayoutContactData(contact));
          } else {
            total[currencyCode] = [generatePayoutContactData(contact)];
          }

          return total;
        },
        {}
      ),
    [recipients]
  );

  const defaultValues = useMemo(() => {
    const newPayoutContactsNames: string[] = [];
    const dataToReturn: Inputs = {
      ...entityPayoutSettings,
      formPayoutContacts: null,
    };
    const savedPayoutContacts = entityPayoutSettings?.payoutContacts;

    if (savedPayoutContacts) {
      dataToReturn.formPayoutContacts = populateContactsForDropdown(
        savedPayoutContacts,
        newPayoutContactsNames,
        recipientById
      );
    }

    setFormPayoutContactsNames(newPayoutContactsNames);

    return dataToReturn;
  }, [entityPayoutSettings, recipientById]);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    unregister,
  } = useForm<Inputs>({
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (entityPayoutSettings) {
      reset(defaultValues);
    }
  }, [entityPayoutSettings, reset, defaultValues]);

  const formPayoutContactsWatch = watch('formPayoutContacts');
  const payoutFrequencyWatch = watch('payoutFrequency');

  const getSelectedPayoutContact = (value?: IContact) => {
    if (!value) {
      return null;
    }

    return generatePayoutContactData(value);
  };

  const currenciesSelectData = useMemo(
    () =>
      sellCurrencies.map((item) => ({
        name: item.code,
        id: item.code,
        icon: item.countryCode,
        value: item.code,
      })),
    [sellCurrencies]
  );

  const onSubmit = async ({ formPayoutContacts, ...values }: Inputs) => {
    if (!entityId) {
      Notify.error(ERROR_MESSAGES.noEntityId);
      return;
    }

    try {
      setIsSubmitting(true);
      const payoutSettings: IEntityPayoutSettings = { ...values };

      payoutSettings.payoutContacts = generatePayoutSettingsToSave({
        formPayoutContacts: formPayoutContacts
          ? Object.values(formPayoutContacts)
          : [],
        savedPayoutContacts: entityPayoutSettings?.payoutContacts,
      });

      const { data } = await updateEntityPayoutSettings({
        entityId,
        settings: payoutSettings,
      });

      if (data?.success) {
        getEntityPayoutSettings(entityId);
        Notify.success('Settings saved successfully.');
      } else {
        errorHandler(data);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onRemovePayoutContact = (accountRowID: string) => {
    unregister(`formPayoutContacts.${accountRowID}`);
    setFormPayoutContactsNames((prevState) =>
      prevState.filter((el) => el !== accountRowID)
    );
  };

  return (
    <>
      <form id="payout-form" onSubmit={handleSubmit(onSubmit)}>
        <WhiteContentContainer>
          <Col>
            <Title mb variant="h4">
              Payout settings
            </Title>

            <PayoutContactsFieldsWrapper>
              <Row alignSelf="stretch" mb justifyContent="flex-start">
                <Paragraph variant="bold" style={{ minWidth: '132px' }}>
                  Payout Frequency:
                </Paragraph>

                <Field ml fluid flexDirection="column">
                  <Controller
                    name="payoutFrequency"
                    control={control}
                    rules={{
                      required: ERROR_MESSAGES.requiredField,
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <StaleInputSelect
                        id={name}
                        name={name}
                        label="Select period"
                        view="moving"
                        data={PAYOUT_FREQUENCY_DATA}
                        selected={value}
                        onSelect={(item) => onChange(item.value)}
                        strategy="fixed"
                        error={error?.message}
                        style={{ width: '100%' }}
                        disabled={isLoading}
                      />
                    )}
                  />
                </Field>
              </Row>

              <Row alignSelf="stretch" mb justifyContent="flex-start">
                <Paragraph variant="bold" style={{ minWidth: '132px' }}>
                  Weekday:
                </Paragraph>

                <Field ml fluid flexDirection="column">
                  <Controller
                    name="weekday"
                    control={control}
                    rules={{
                      required: ERROR_MESSAGES.requiredField,
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <StaleInputSelect
                        id={name}
                        name={name}
                        label={
                          payoutFrequencyWatch
                            ? `${payoutFrequencyWatch} Payout in last`
                            : 'Select payout frequency period'
                        }
                        view="moving"
                        data={WEEKDAY_DATA}
                        selected={WEEKDAY_DATA.find((el) => el.value === value)}
                        onSelect={(item) => onChange(item.value)}
                        strategy="fixed"
                        error={error?.message}
                        style={{ width: '100%' }}
                        disabled={isLoading || !payoutFrequencyWatch}
                      />
                    )}
                  />
                </Field>
              </Row>

              <Row alignSelf="stretch" mb justifyContent="flex-start">
                <Paragraph variant="bold" style={{ minWidth: '132px' }}>
                  Maximum balance:
                </Paragraph>

                <Field ml fluid>
                  <Controller
                    name="balanceAmount"
                    control={control}
                    rules={{
                      required: ERROR_MESSAGES.requiredField,
                    }}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <InputCurrencyUncontrolled
                        id={name}
                        label={`${entityCurrencyCode} Amount`}
                        view="moving"
                        type="text"
                        currencyPrefix={entityCurrencyCode}
                        value={value}
                        onChange={onChange}
                        ref={ref}
                        wrapperStyle={{ width: '100%' }}
                      />
                    )}
                  />
                </Field>
              </Row>
            </PayoutContactsFieldsWrapper>
          </Col>

          <Col>
            <Title mb variant="h4">
              Payout contacts
            </Title>

            <PayoutContactsFieldsWrapper>
              {formPayoutContactsNames.length > 0 && (
                <>
                  {formPayoutContactsNames.map((contactRowID) => {
                    let payoutContactsData: IPayoutContactData[] = [];

                    const isCurrencySelected = !!formPayoutContactsWatch?.[
                      contactRowID
                    ]?.currency;

                    const selectedCurrency =
                      formPayoutContactsWatch?.[contactRowID]?.currency;

                    if (selectedCurrency) {
                      const filteredContacts =
                        payoutContactsGroupedByCurrency?.[selectedCurrency];

                      if (payoutContactsGroupedByCurrency && filteredContacts) {
                        payoutContactsData = filteredContacts;
                      }
                    }

                    const currencyForAddRecipient = currencyByCode(
                      selectedCurrency
                    );

                    return (
                      <Row
                        key={contactRowID}
                        alignSelf="stretch"
                        mb
                        alignItems="flex-start"
                      >
                        <Field flex="unset" flexDirection="column">
                          <Controller
                            name={`formPayoutContacts.${contactRowID}.currency`}
                            control={control}
                            rules={{
                              required: ERROR_MESSAGES.requiredField,
                              validate: () => {
                                if (
                                  hasDuplicatesInArray(
                                    Object.values(
                                      formPayoutContactsWatch || {}
                                    ).map((item) => item.currency)
                                  )
                                ) {
                                  return 'Currency already exists';
                                }

                                return true;
                              },
                            }}
                            render={({
                              field: { onChange, value, name },
                              fieldState: { error },
                            }) => (
                              <StaleInputSelect
                                id={name}
                                data={currenciesSelectData}
                                label={isCurrencySelected ? undefined : 'CCY'}
                                view="moving"
                                selected={value}
                                onSelect={(item) => onChange(item.value)}
                                style={{ minWidth: '132px' }}
                                error={error?.message}
                                disabled={isLoading}
                              />
                            )}
                          />
                        </Field>

                        <Field ml fluid flexDirection="column">
                          <Controller
                            name={`formPayoutContacts.${contactRowID}.payoutContact`}
                            control={control}
                            rules={{
                              required: ERROR_MESSAGES.requiredField,
                            }}
                            render={({
                              field: { onChange, value, name },
                              fieldState: { error },
                            }) => (
                              <StaleInputSelect
                                id={name}
                                name={name}
                                label={generateLabelText({
                                  isCurrencySelected,
                                  selectedCurrency,
                                  value,
                                  payoutContactsData,
                                })}
                                view="moving"
                                data={payoutContactsData.filter((item) =>
                                  item?.name
                                    .toLowerCase()
                                    .trim()
                                    .includes(
                                      payoutContactsSearchValue
                                        .toLowerCase()
                                        .trim()
                                    )
                                )}
                                selected={getSelectedPayoutContact(value)}
                                onSelect={(item) => onChange(item.value)}
                                strategy="fixed"
                                error={error?.message}
                                style={{ width: '100%' }}
                                withSearch
                                searchValue={payoutContactsSearchValue}
                                onSearch={(e) =>
                                  setPayoutContactsSearchValue(
                                    e.currentTarget.value
                                  )
                                }
                                disabled={
                                  !isCurrencySelected ||
                                  payoutContactsData.length <= 0 ||
                                  isLoading
                                }
                              />
                            )}
                          />
                        </Field>

                        <Button
                          mt
                          variant="link"
                          onClick={() => onRemovePayoutContact(contactRowID)}
                          icon="remove-ico"
                        />

                        {currencyForAddRecipient && (
                          <Button
                            mt
                            variant="link"
                            disabled={!isCurrencySelected || isLoading}
                            onClick={() =>
                              setRecipientDataForAdd({
                                currency: currencyForAddRecipient,
                                contactRowID,
                              })
                            }
                            icon="round-plus-ico"
                          />
                        )}
                      </Row>
                    );
                  })}
                </>
              )}

              <Button
                variant="link"
                onClick={(e) => {
                  e.preventDefault();
                  setFormPayoutContactsNames((prevState) => [
                    ...prevState,
                    `contact_${prevState.length + 1}`,
                  ]);
                }}
                disabled={isLoading}
                icon="round-plus-ico"
              >
                Add account
              </Button>
            </PayoutContactsFieldsWrapper>
          </Col>
        </WhiteContentContainer>
        <WhiteFooterContainer>
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            mt
            mtValue={theme.spacing.xl}
            type="submit"
          >
            Save
          </Button>
        </WhiteFooterContainer>
      </form>

      {recipientDataForAdd && (
        <AddContactForm
          onClose={() => setRecipientDataForAdd(null)}
          withSaveAsDraft={false}
          sellCurrency={recipientDataForAdd.currency}
          initialCurrency={recipientDataForAdd.currency}
          initialTransferType={TRANSFER_TYPE.priority}
          setRecipient={(recipient) => {
            setValue(
              `formPayoutContacts.${recipientDataForAdd.contactRowID}.payoutContact`,
              recipient
            );
            setRecipientDataForAdd(null);
          }}
          onContinue={() => {
            setRecipientDataForAdd(null);
          }}
          recipientForEdit={null}
          disableCurrency
        />
      )}
    </>
  );
};

export default PayoutSettings;
