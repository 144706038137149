import { IIntegrationSettingsAccount, IIntegrationSettingsAll } from 'types';
import { IFormBankTransferXeroAccount } from './StepAccountsForm';

export const populateBankTransferAccountsForDropdown = (
  accounts: IIntegrationSettingsAccount[],
  formBankTransferAccountsNames: string[]
) =>
  accounts.reduce<Record<string, IFormBankTransferXeroAccount>>(
    (accumulator, account) => {
      if (account.type !== 'bankTransferAccount') {
        return accumulator;
      }

      const accountRowId = `bankAccount_${account.id || account.name}`;
      formBankTransferAccountsNames.push(accountRowId);

      accumulator[accountRowId] = {
        currency: account.currency,
        account,
      };

      return accumulator;
    },
    {}
  );

export const getStepAccountsFormInitialValues = ({
  integrationEngineSettings,
  isNonXeroIntegration,
  isXeroIntegration,
  isDynamics,
}: {
  integrationEngineSettings: IIntegrationSettingsAll;
  isNonXeroIntegration: boolean;
  isXeroIntegration: boolean;
  isDynamics: boolean;
}) => {
  /**
   * Defaults to true if it's not set or present, otherwise use the existing saved value
   * */
  const initialBankTransfersPermission =
    integrationEngineSettings.permissions?.bankTransfers !== undefined
      ? integrationEngineSettings.permissions.bankTransfers
      : true;
  const initialInvoicesAndBillsPermission = integrationEngineSettings.permissions
    ? !!integrationEngineSettings.permissions.invoices &&
      !!integrationEngineSettings.permissions.purchaseOrders &&
      !!integrationEngineSettings.permissions.bills
    : !isNonXeroIntegration;
  const initialPaymentsAndConversionsPermission = integrationEngineSettings.permissions
    ? !!integrationEngineSettings.permissions.payments &&
      !!integrationEngineSettings.permissions.accounts
    : true;
  const initialPaymentFeesPermission = integrationEngineSettings.permissions
    ? !!integrationEngineSettings.permissions.bankFees
    : isXeroIntegration || isDynamics;

  return {
    initialBankTransfersPermission,
    initialInvoicesAndBillsPermission,
    initialPaymentsAndConversionsPermission,
    initialPaymentFeesPermission,
  };
};

export const calculateBankTransferAccounts = ({
  formBankTransferAccountsNames,
  integrationEngineSettings,
}: {
  formBankTransferAccountsNames: string[];
  integrationEngineSettings: IIntegrationSettingsAll;
}) => {
  const savedIntegrationAccounts: IIntegrationSettingsAccount[] =
    integrationEngineSettings.accounts || [];

  if (savedIntegrationAccounts.length > 0) {
    return populateBankTransferAccountsForDropdown(
      savedIntegrationAccounts,
      formBankTransferAccountsNames
    );
  }
};
